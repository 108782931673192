.hotel_details {
	.cancelation_bg{
		background-color: #e8eaec;
	}
	.room_details_container {
		display: flex;
		width: 100%;
		overflow: hidden;
	}
	.bg-light-gray{
		background-color: #F3F3F3 !important;
	}
	.room_title {
		flex-grow: 1 !important;
	}
	.room_Sub_title {
		color: #008128;
		margin-bottom: 0;
	}
	.MuiTabs-indicator {
		background-color: $b2cPrimary;
	}
	.select_room {
		/* align-items-center p-2 justify-content-between d-flex w-100 */
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 8px;
		width: 100%;
		background-color: #e8eaec;
	}

	.room_price {
		color: #0f3e5e;
		font-weight: bold;
		margin-bottom: 0;
	}

	.selecet_button {
		background-color: white !important;
		margin-inline-start: 8px !important;
	}

	.cancelation_Policies {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.cancelation_title {
		font-size: 14px;
		font-weight: bold;
	}

	.dates {
		font-size: 12px;
		font-weight: bold;
	}

	.line,
	.first_line {
		font-size: 13px;
	}

	.non_refund_color {
		color: #ce0012 !important;
		border-color: #ce0012 !important;
	}
	.refund_color {
		color: #0f3e5e !important;
		border-color: #0f3e5e !important;
	}

	.line {
		border-top: 2px solid;
		width: 50%;
		position: relative;
		text-align: center;
	}

	/* .last_line {
  border-top: 2px solid ;
  width: 50%;
  position: relative;
  text-align: center;
} */

	.first_line::before {
		content: "";
		position: absolute;
		width: 10px;
		height: 10px;
		border: 1px solid;
		border-radius: 50%;
		left: 0;
		top: -6px;
		background-color: white;
	}

	.first_line::after {
		content: "";
		position: absolute;
		width: 10px;
		height: 10px;
		border: 1px solid;
		border-radius: 50%;
		right: 0;
		top: -6px;
		background-color: white;
	}
	.next_line::after {
		content: "";
		position: absolute;
		width: 10px;
		height: 10px;
		border: 1px solid;
		border-radius: 50%;
		right: 0;
		top: -6px;
		background-color: white;
	}

	.image_gallery_item {
		height: 400px !important;
		left: 12px;
	}

	.policies_field {
		padding: 10px;
		background-color: #f2eee7;
		border-radius: 4px;
		margin: 0 10px;
	}

	.summary_box {
		background-color: white;
	}
}

.close_icon {
	border-radius: 4px !important;
	background-color: #d1d6dd !important;
	color: white !important;
	width: 32px;
	height: 32px;
}
