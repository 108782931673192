#reserv-terms-conditions {
	.terms_conditions_header {
		p {
			margin: 0;
			flex-grow: 1;
			background-color: #c8c8c8;
			padding: 6px;
			font-size: 16px;
			color: #354f5c;
		}
	}

	.terms_conditions_filed {
		textarea {
			height: 50vh;
		}
		.control-field__body {
			padding: 0;
		}
	}
}
