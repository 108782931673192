.main-filter {
	display: flex;
	padding: 1rem 0.5rem;
	border: 1px solid #d1d6dd;
	border-bottom: none;
	margin: 0px !important;
	.main-label {
		position: relative;
		margin-bottom: 0;
		width: 100%;
	}
	.main-input-search {
		border: 1px solid $gray-200;
		height: 35px;
		font-size: $text-caption - 1;
		padding: 0 0.3rem;
		width: 100%;
		outline: none;
		margin: 0 0.2rem;
		color: #757575;
		border-radius: 5px;
		overflow: hidden !important;
		box-shadow: none;
	}
	.icx,
	i {
		position: absolute;
		bottom: 17px;
		// #{var(--right)}: 2px;
		right: 17px;
		font-size: $text-body;
		color: $gray-300;
		[dir="rtl"] & {
			right: auto;
			left: 17px;
		}
	}

	.reset-filter {
		display: flex;
		align-items: center;
	}
}
// o remove margine in mine filter
.main-filter[class*="mx"],
.main-filter[class*="my"] {
	margin: 25px 0px 0px 0px !important;
}
