/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    01. Text Colors
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.text-gray-100 {
	color: $gray-100;
}

.text-gray-200 {
	color: $gray-200;
}

.text-gray-300 {
	color: $gray-300;
}

.text-dark-blue {
	color: $dark-blue;
}

.text-blue {
	color: $blue;
}

.text-red {
	color: $red;
}

.text-orange {
	color: $orange;
}

.text-green {
	color: $green !important;
}

.text-green-100 {
	color: $green-100 !important;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    02. Background Colors
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.bg-gray-100 {
	background-color: $gray-100;
}

.bg-gray-150 {
	background: $gray-150 !important;
}

.bg-gray-200 {
	background-color: $gray-200;
}

.bg-gray-300 {
	background-color: $gray-300;
}

.bg-dark-blue {
	background-color: $dark-blue;
}

.bg-blue {
	background-color: $blue;
}

.bg-red {
	background-color: $red;
}

.bg-orange {
	background-color: $orange;
}

.bg-green-100 {
	background-color: $green-100 !important;
}

.bg-green {
	background-color: $green;
}

.bg-orange {
	background-color: $orange !important;
}

.bg-success {
	background-color: $green !important;
}

.bg-primary {
	background-color: $blue !important;
}

.bg-red {
	background-color: $blue !important;
}

.bg-gray {
	background: #7c7c7c !important;
}

.bg-gray-100 {
	background: #ddd !important  ;
}

.bg-dark-gray {
	background: $dark-gray !important;
}
