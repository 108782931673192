.product-builder-list {
	background-color: $gray-100;
	padding: 0.3rem;
}
.product-header-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 0;
	button {
		margin: 0 0.5rem;
		padding: 0.375rem 2rem;
		font-size: $text-caption;
	}
} //product-header-list

.custom-table {
	font-size: $text-caption;
	/* 	border-collapse: separate; */
	border-spacing: 0 6px;
	margin-bottom: 0.3rem;
	/* 	border: 1px solid;
 */
	td,
	th {
		padding: 0.5rem;
		vertical-align: middle;
		border-top: none;
	}
	td {
		&:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			[dir="rtl"] & {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}
		}
	}
	th {
		&:first-child {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			[dir="rtl"] & {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
		&:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			[dir="rtl"] & {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}
		}
	}
	thead {
		/*     background-color: $dark-blue;
 */
		color: #0000 !important;
		th {
			border-bottom: 1px solid $dark-blue;
		}
	}
	tbody {
		// tr:nth-child(odd) {
		// 	background: $white;
		// 	box-shadow: 0px 0px 1px #7d7d7d;
		// 	border-radius: 8px;
		// 	overflow: hidden;
		// }

		// tr:nth-child(even) {
		// 	background: #e8e8e8;
		// 	box-shadow: 0px 0px 1px #7d7d7d;
		// 	border-radius: 8px;
		// 	overflow: hidden;
		// }
		.icx {
			font-size: $text-headline;
			margin: 0 0.5rem;
		}
		.products {
			i {
				color: $dark-gray;
				margin: 0 0.3rem;
			}
		}
		.actions {
			i,
			.icx {
				cursor: pointer;
			}
		}
		.action--btn {
			color: #1877f9;
			margin-inline-end: 0.75rem;
		}
	}
	.btn-success,
	.btn-danger,
	.btn-warning {
		padding: 0.2rem 1rem;
		border-radius: 4px;
		font-size: 12px;
		color: #fff;
		min-width: 80px;
		display: inline-block;
		text-align: center;
	}
	.btn-warning {
		background-color: #f28f08;
		border-color: #f28f08;
	}
} //custom-table

.table-container {
	overflow-x: auto !important;
}

.g-table {
	border: 2px solid #dbdade;
	width: 100%;
	margin-block: 10px;
	background-color: #ffffff;
	border-radius: 10px;
	border-collapse: separate;
	border-spacing: 0px;
	width: 100% !important;

	thead {
		background: #f6f6f6;
		color: #5d5966;
		letter-spacing: 1px;
		text-align: start;
	}

	th {
		padding: 20px 16px;
		text-align: start;
		border-bottom: 1px solid #dbdade !important;
		font-weight: 700;
		font-size: 16px;
	}

	td {
		padding: 20px 18px;
		border-bottom: 1px solid #dbdade !important;
		min-height: 74px !important;
		font-size: 16px;
	}

	tr:last-child td {
		border-bottom: 0px !important;
	}
	.gray-text {
		color: #707170;
	}
}
