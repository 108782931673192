.vendor {
	.bg-bookingView {
		background-color: #2c302e;
		color: #fff;
		border-bottom: 1px solid #fbfaff;
	}

	.vendor-wallet {
		.bg-wallet-name {
			background-color: #2c302e;
			transition: 0.5s;
			color: white;
		}
		.collapse-border {
			background-color: #fff;
			border: 1px solid #a7afb7 !important;
		}
		.border-top {
			.bg-wallet-name {
				background-color: #cb9a51;
				transition: 0.5s;
				color: white;
			}
		}
	}
	.collapse-border {
		button {
			background-color: #00aaeb !important;
			border-color: #00aaeb !important;
			color: #fff !important;
		}
	}

	.modal-dialog {
		max-width: 700px !important;
	}
}
.vendor-commissions {
	.modal-dialog {
		background-color: #09c !important;
		//	max-width: 300px !important;
	}
}
.commission-modal {
	max-width: 700px !important;
	top: 20% !important;
	.control-field__append {
		position: absolute;
		height: 37px;
		bottom: 6px;
		right: 10px;
		background: #d1d6dd;
		width: 35px;
	}
}
.img-service div {
	display: inline-flex;
	background-color: #deeaf9;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
}

//search hotels
.global-input {
	height: auto !important;
	padding: 12px 15px !important;
	color: #354f5c;
	border-radius: 0px;
	border: 0px;
	font-weight: bold;
	font-size: 15px;
	cursor: pointer;
	width: 100%;
	border: 1px solid #dee2e6 !important;
	border-radius: 5px;
}
.HotelSearch label {
	text-transform: capitalize;
	font-weight: 600;
}
.branch-collapse {
	.branch-collabse-row {
		.col-sm-6 {
			padding: 0 auto !important;
			line-height: 0.5 !important;
		}
	}
	.button-collapse {
		background: #fcfcfc;
		border: 1px solid #e8e8e8;
		border-radius: 8px !important;
		.control-field__body {
			position: relative;
			padding: 0.625rem 0.625rem 0.3125rem 0.625rem !important;
		}
	}
	.card-body {
		.head {
			padding: 0.5rem;
			p {
				font-size: 14px;
				&:first-child {
					color: #3f4f61;
				}
				&:last-child {
					color: $gray-300;
				}
			}
		}
	}
}
.title-style-container {
	display: flex;
	align-items: center;
	width: 100%;
	font-size: $text-caption;
	.right-items-container {
		min-width: 10%;
		width: 98%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		.img-circle-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			line-height: 50px;
			background-color: $blue;
			margin: 0px 10px;
			padding: 20px;
			position: relative;
			z-index: 1;
			span {
				font-size: $text-title2;
				color: #fff;
			}
		}
	}
	.icon-items-section {
		.vertical {
			height: 100%;
			position: absolute;
			top: 0;
			// right: 55px;
			border-left: 1px solid #c8c7c7;
		}
		.fa-trash-alt {
			margin-top: 0px;
			margin-right: 5px;
			margin-left: 5px;
			color: $red;
			font-weight: normal;
		}
		i {
			font-size: $text-title3;
			cursor: pointer;
		}
	}
}

.number-icon {
	font-size: 17px;
	cursor: pointer;
}
.search-hotels {
	width: 80%;
	margin: auto;
	.control-field__body {
		position: relative;
		padding: 0.625rem 0 0.3125rem 0;
		.no-gutters {
			width: 100% !important;
		}
		.no-gutters + div {
			position: absolute;
			right: 29px;
			display: flex;
			flex-direction: column;
			top: 20px;
		}
		.global-input {
			padding: 15px 45px 0px 10px;
		}
	}
	.search-hotels-autoComp-feild {
		.control-field__body {
			padding: 0px 0.3125rem !important;
		}
	}
	.DateInput_input {
		padding: 0 12px !important;
	}
	.booking-autoComplete {
		.control-field__body {
			.no-gutters {
				position: relative;
				.fix-flag {
					position: absolute;
					top: 9px;

					width: 37px;
					left: 5px;
					z-index: 55;
				}
			}
		}
	}
}
.view-genertaLink {
	.view-rooms-marketing {
		.control-field__body {
			.number-icon {
				display: none !important;
			}
		}
	}
	.control-field__body {
		padding: 0 !important;
	}
	.SingleDatePicker {
		padding: 8px 11px !important;
	}
}

.bg-Rooms {
	background-color: #ededed;
}
.vendor-wallet {
	.btn-yellow {
		background: #cb9a51;
		color: #ffd;
	}
	.table {
		tr {
			th {
				color: #313533 !important;
			}
		}
	}
}
.vendor-wallet-view-popup {
	height: 38px !important;
}
.border-gray {
	border: 1px solid #c9c9c9;
}
.booking-autoComplete {
	margin-top: 7px !important;
	.control-field__label {
		margin: 0 4px !important;
	}
	.control-field__body {
		padding: 0 0.3125rem !important;
		.no-gutters {
			position: relative;
			.fix-flag {
				position: absolute;
				top: 9px;

				width: 37px;
				left: 5px;
				z-index: 55;
			}
		}
	}
}

.price_start_ads_field {
	.control-field__append {
		position: absolute;
		top: 50%;
		left: 71%;
		border-inline-start: 0px !important;
		.form-control {
			padding: 0 !important;
			color: #cacaca !important;
			font-size: 14px !important;
		}
	}
}

.w-70 {
	width: 70% !important;
}
.mine-box {
	border: 1px solid #d1d6dd;
	.box-title {
		width: 100%;
		padding: 10px;
		border-bottom: 1px solid #d1d6dd;
	}
}
.img-box-ven {
	width: 100%;
	height: 200px;
	border: 1px solid #d1d6dd;
	background-color: #fff;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	.remove-photo {
		position: absolute;
		top: 8px;
		right: 8px;
		background-color: #ee2548;
		padding: 3px;
		border-radius: 50%;
		color: #fff;
		font-size: 10px;
	}
}

.contaract-box {
	border: dashed 2px #d1d6dd;
	width: 100;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.all-modules {
	display: flex;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
}
.module-box {
	border: 1px solid #d1d6dd;
	background-color: #fff;
	border-radius: 5px;
	padding: 3px 5px;
	width: 250px;

	p {
		margin-bottom: 0px !important;
	}
}
.handel-head {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	.fa-cog {
		color: #d1d6dd;
		font-size: 30px;
	}
	.fa-ellipsis-v {
		align-self: baseline;
	}
}
.toggle-status {
	background-color: #fff;
	padding: 4px;
	position: absolute;
	bottom: 0px;
	right: -30px;
	font-size: 11px;
	border-radius: 5px;
	font-weight: 100;
}
.item-service {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	label {
		margin-bottom: 0px;
		display: flex;
		align-items: center;
		gap: 10px;
		text-transform: capitalize;
	}
}

.contract-files {
	display: flex;
	background-color: #f2f2f2;
	padding: 8px !important;
	align-items: center;
	.contract-files-text {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 220px;
		display: inline-block;
		margin-bottom: 0;
	}
}

.commercial-dist-active-pill {
	padding: 7px 18px;
	background-color: #d6f4e1;
	color: #00b545;
	border-radius: 50px;
}
.commercial-dist-inactive-pill {
	padding: 7px 18px;
	background-color: #f4d6d6;
	color: #b50000;
	border-radius: 50px;
}
