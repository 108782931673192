.reservation-table {
  td {
    text-transform: capitalize;
  }
}
.text-pending {
  color: #B17A00;
}
.text-definite {
  color: #00B545;
}
.text-canceled {
  color: #EE2548;
}