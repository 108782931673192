.bank-transfer-section {
	// padding: 2rem 0;
	h6 {
		color: #324356;
	}
	.bank-information {
		background-color: $gray-200;
		border: 1px solid $gray-200;
		border-radius: 5px;
		width: 90%;
		text-align: center;
		.img-box,
		.bank-info {
			padding: 2rem;
		}
		.bank-info {
			h6,
			p {
				width: 70%;
				margin: auto;
				color: #324356;
			}
			.bank-head {
				font-weight: bold;
				color: $dark-blue;
			}
		}
	} //.bank-information
	.receipt-information {
		background-color: $white;
		border: 1px solid $gray-200;
		border-radius: 5px;
		// text-align: center;
		.img-box {
			width: 70px;
			height: 100px;
			margin: auto;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.img-box,
		.receipt-info {
			.full-input {
				width: auto;
				background: #fff;
				padding: 5px 10px;
				position: relative;
				text-align: var(--left);
				&:focus {
					border: 1px solid #0184fe !important;
					outline: none;
					:host(:focus) {
						background-color: red;
					}
				}

				input {
					margin-top: 0.3rem;
				}
				.fa-calendar-alt {
					position: absolute;
					bottom: 16px;
					right: 7px;
					color: #b9bdc2;
					font-size: 20px;
				}
			}
			.national-num {
				width: 90px;
				border-radius: 0px 5px 5px 0px;
				display: flex;
				input {
					text-align: center;
				}
			} //national-input
			.input-number {
				width: 100%;
				border-radius: 5px 0px 0px 5px;
			}
		}
		.input-upload {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			> p {
				font-size: $text-caption;
			}
			label {
				cursor: pointer;
				margin-bottom: 0;
				.shapes {
					// margin-#{var(--left)}: 1rem;
					margin-inline-end: 1rem;
					border: 1px dashed #c6cbd0;
					border-radius: 5px;
					width: 100px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					i {
						color: #c6cbd0;
					}

					.image-uploader__img {
						display: block;
						margin: 0 auto;
						width: 100%;
						padding: 0.2rem;
						height: 100%;
						border-radius: 0;
					}
				} //shape
			}
			input {
				display: none;
			}
		} //input-upload
	} //receipt-information

	.bank-information,
	.receipt-information {
		min-height: 250px;
	}
	.transfer-buttons {
		display: flex;
		justify-content: space-between;
		margin: 1rem 0;
		a {
			color: $dark-blue;
		}
		.submit-btn {
			padding: 0.375rem 4.75rem;
		}
	}
} //bank-transfer-section
