.marketplace-details {
	.box-container {
		margin-bottom: 15px;
		background-color: #fff;
		padding: 12px !important;
		box-shadow: 2px 2px 5px rgb(0 0 0 / 11%);
	}
	.absolute-header {
		position: absolute;
		top: -10px;
		background-color: #fff;
		margin: 0 30px;
		font-size: 19px;
		padding: 0 10px;
		color: #007bff;
		font-weight: 700;
		text-transform: uppercase;
	}
	.booking-details {
		position: relative;
		padding: 0.75rem;

		.key-side {
			background-color: #ebebeb;
			padding: 10px 17px 10px 7px;
			h5 {
				color: #0c3b5c;
				font-size: 14px;
				font-weight: 700;
				line-height: 1.5 !important;
			}
		}
		.value-side {
			border-bottom: 1px solid #dfdfdf;
			border-right: 1px solid #dfdfdf;
			padding: 3px 8px;
			display: flex;
			align-items: center;

			p {
				color: #2d2d2d;
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
				margin-bottom: 0;
			}
			p.font-weight-bold {
				font-weight: 700;
			}
		}
	}
	.gray-box {
		margin-bottom: 15px;
		background-color: #f1f4f7;
		padding: 12px 12px !important;
		box-shadow: 2px 2px 5px rgb(0 0 0 / 11%);
		h5 {
			color: #484848 !important;
		}
		ul li {
			background-color: transparent;
			border: none;
			padding: 5px 7px;
			display: inline-block;
			margin: 2px 0;
			width: 33%;
		}
	}
}

.passenger-details thead th {
	border-bottom: 0 !important;
	color: #0c3b5c !important;
	font-size: 15px;
	font-weight: 700;
	background-color: white;
}
