.b2c-users {
	.active {
		color: #1cba58 !important;
	}
}
.ota {
	padding: 6px;
	border-radius: 4px;
	border: 1px solid !important;
	&.converted-btn {
		background-color: #019d4c1a;
		color: #019d4c;
		border-color: #019d4c;
	}
	&.canceled-btn {
		background-color: #f40f0f1a;
		color: #f40f0f;
		border-color: #f40f0f;
	}
	&.pending-btn {
		background-color: #ffa8001a;
		color: #ffa800;
		border-color: #ffa800;
	}
	&.expired-btn {
		background-color: #7071701a;
		color: #707170;
		border-color: #707170;
	}
	&.new-btn {
		background-color: #1e85ff1a;
		color: #1e85ff;
		border-color: #1e85ff;
	}
}
.request-feild {
	background-color: #f7f6f6;
	.request-label {
		font-size: 14px;
		color: #707170;
	}
	.request-input {
		font-size: 16px;
		color: #050001;
	}
}
