@mixin field-append {
	background-color: #d1d6dd !important;
	padding: 5px 8px;
	border-radius: 2px;
	border-start-start-radius: 0;
	color: #b3bbc2 !important;
	border-end-start-radius: 0;
}

.tap-status {
	position: absolute;
	inset-inline-end: 0;
	inset-block-end: 0;
}

.tap-status span:last-child {
	color: darkgoldenrod;
	font-weight: bold;
}
.tap-status .definite_color {
	color: #00b545 !important;
}

.tap-status .cancel_color {
	color: #ee2548 !important;
}

.btn-gray {
	font-size: 14px !important;
	color: #303032;
	background-color: #e9e9ef !important;
	box-shadow: 0px 3px 6px #e9e9ef !important;
	border-radius: 3px;
}
.btn-gray:hover {
	background-color: #c6c6cf !important;
}

.btn-dark-orange {
	font-size: 14px !important;
	color: white !important;
	background-color: #b17a00 !important;
	box-shadow: 0px 3px 6px #b17a004d !important;
	border-radius: 3px;
}
.btn-dark-orange:hover {
	background-color: #c18c18 !important;
}

#sbs-reservations-main {
	.nav-item .nav-link.active {
		border: 1px solid #d1d6dd !important;
		border-radius: 5px 5px 0px 0px;
		background-color: #fbfaff !important  ;
		border-bottom: none !important;
		color: $green;
		font-weight: bold;
	}
	.nav.nav-tabs {
		border-bottom: 1px solid #ced4da;
	}

	.control-field__append {
		inset-inline-end: 4px;
	}
	.control-field__append .form-control {
		@include field-append;
	}

	.btn-cancel {
		background-color: #ee2548;
		box-shadow: 0px 3px 6px #ee254853;
		color: white;
	}
}

.reserv_modal {
	margin-bottom: 20px;
	justify-content: space-between;

	.cancel_color {
		color: #ee2548 !important;
	}
	.cancel_bg_color {
		background-color: #ee2548 !important;
	}
	.calc-width {
		width: calc(50% - 8px);
	}

	.control-field__append {
		inset-inline-end: -4px;
		inset-block-end: 9px;
	}

	.control-field__append .form-control {
		@include field-append;
	}

	&_total_price {
		text-align: center;
		background-color: #cfead5;
		border: 1px solid #d1d6dd;
		border-radius: 3px;
		padding: 16px;
	}

	&_danger_price {
		text-align: center;
		background-color: #ee25482b;
		border: 1px solid #ee25482b;
		border-radius: 3px;
		padding: 16px;
	}

	&_new_price {
		text-align: center;
		background-color: #eaeaea;
		border: 1px solid #d1d6dd;
		border-radius: 3px;
		padding: 16px;
	}
	&_danger_price p,
	&_total_price p,
	&_new_price p {
		margin-bottom: 3px;
	}

	&_total_price p:last-child,
	&_new_price p:last-child {
		font-weight: bold;
	}

	&_radio .form-check-input:checked,
	&_radio .form-check-input {
		background-color: white !important;
		border-width: 2px;
		width: 16px;
		height: 16px;
		border-color: #707070 !important;
	}

	&_radio .form-check-input:checked {
		border-color: $green !important;
		position: relative;
	}

	&_radio .form-check-input:checked:before {
		content: "";
		width: 8px;
		position: absolute;
		height: 8px;
		background-color: $green;
		border-radius: 50%;
		bottom: 2px;
		left: 2px;
	}
	&_radio .pointer {
		vertical-align: middle;
		font-weight: bold;
		color: #707070 !important;
	}

	&_radio .pointer-checked {
		color: $green !important;
	}

	&_payment_fields {
		justify-content: space-between;
		padding: 0 !important;

		& div.col-6:last-child .control-field__body,
		& div.payment_field_model .control-field__body {
			padding: 0;
		}
		& div.col-6:first-child .control-field__body,
		& div.col-12:first-child .control-field__body {
			padding-bottom: 0.5125rem;
		}

		.remove_room_icon,
		.remove_add_icon {
			position: absolute;
			top: 50%;
			inset-inline-end: -22px;
			background: transparent;
			padding: 0;
		}

		.remove_add_icon {
			inset-inline-end: -42px;
			color: $green;
		}
	}

	.btn-res-primary {
		font-size: 1rem !important;
		color: #ffffff;
		background-color: #00b680;
		box-shadow: 0px 3px 6px #00b68053;
		border-radius: 3px;
	}
	.btn-res-primary:hover {
		background-color: #0ddd9f;
	}

	.upload_photo {
		border: dashed 1px #d1d6dd;
	}
	.red-Border {
		border-color: red !important;
		border-width: 2px;
	}
}

.view_transactions_modal {
	.col-6 p,
	.col-6 h5 {
		margin-bottom: 0.5rem;
		font-size: 17px;
		color: $dark-blue;
	}
	.col-6 h5 {
		margin-bottom: 0.7rem;
	}
}
