.b2c_secondary_color {
	color: $b2cSecondary !important;
}
.b2c_primary_color {
	color: $b2cPrimary !important;
}

.b2c_booking_details {
	.header_color {
		background-color: #faf2e8;
	}
	.booking_tab_container {
		width: 100%;
		background-color: #e8eaec;
		display: flex;
		.booking_tab {
			background-color: #d2d2d2;
			display: flex;
			align-items: center;
			path {
				fill: black;
			}
		}
		.booking_tab.active {
			background-color: $b2cPrimary;
			border-bottom: 3px solid black !important;
			path {
				fill: white;
			}
			h6 {
				color: white !important;
			}
		}
	}
}
.btn-color {
	background-color: #b17a1f !important;
}
.text-priority-danger {
	color: #df5c58 !important;
}
.text-priority-warning {
	color: #b17a1f !important;
}
