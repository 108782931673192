.wallet-wrraper {
	background: url(../../../assets/images/wallet/wallet.png);
	background-position: 0 10em;
	background-repeat: no-repeat;
	background-size: cover;

	.current-balance-box {
		.current-head {
			margin-bottom: 0.2rem;
			color: #324356;
		}
		.current-price {
			font-size: $text-caption + 38;
			margin: 0;
			color: #324356;
			margin-right: 1rem;
		}
		.select-balance {
			height: 30px;
			background-color: #0184fe;
			color: #fff;
			border: none;
			width: 15%;
			border-radius: 3px;
			cursor: pointer;
		}
	} //current-balance-box

	.add-credit-box {
		.current-head {
			color: #324356;
			margin: 0;
		}
		.btn-success {
			margin: 0.3rem 0.5rem 0.3rem 0rem;
			padding: 1rem;
		}
	} //add-cerdit-box
}

.arrows-box {
	display: flex;
	justify-content: center;
	flex-direction: column-reverse;
	padding: 0 0.5rem;
	i {
		margin: -3px;
	}
}

.main-filter-payment {
	.main-label {
		position: relative;
		margin-bottom: 0;
		width: 100%;
	}
	.main-input-search {
		border: 1px solid $gray-200;
		height: 35px;
		font-size: $text-caption - 1;
		padding: 0 0.3rem;
		width: 100%;
		outline: none;
		margin: 0 0.2rem;
		color: #757575;
		border-radius: 5px;
		overflow: hidden !important;
		box-shadow: none;
	}
	.icx,
	i {
		position: absolute;
		bottom: 11px;
		right: 2px;
		font-size: $text-body;
		color: $gray-300;
		[dir="rtl"] & {
			right: auto;
			left: 2px;
		}
	}

	.reset-filter {
		display: flex;
		align-items: center;
	}
} //main-filter

//Summary
.recharts-wrapper {
	position: absolute !important;
	right: 0;
	[dir="rtl"] & {
		right: auto;
		left: 0;
	}
}

.modal-transaction {
	.modal-header {
		.close {
			margin: -1rem 0 !important;
		}
	}
}

.rounded_layers {
	border: 1px solid #dbe6f1;
	border-radius: 10px;
}

.balance_box {
	display: flex;
	flex-direction: column;
	background-color: #deeaf3;
	border: 1px solid #dbe6f1;
	border-radius: 10px;
	align-items: center;
	font-weight: 500;
	font-size: 1.3rem;
	padding: 10px 60px 10px 60px;
}
.sadd {
	width: 49%;
}
.balance_add {
	font-family: "Cairo";
	font-size: 1.3rem;
	font-weight: 700;
	color: #00b437;
}
.current_tab {
	background-color: #0184fe;
	color: rgb(255, 255, 255);
	border-radius: 5px;
	padding: 0.6rem;
	font-size: 1.2rem;
	border: 1px solid #c6cbd0;
}
.tab {
	border: 1px solid #c6cbd0;
	border-radius: 5px;
	padding: 0.6rem;
	font-size: 1.2rem;
	background: transparent;
}
.bg-filter {
	background-color: #d3d9de;
}
.create-card {
	background-color: white;
	border: 1px solid #c6cbd0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 65%;
	padding: 3rem;
}
.display-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.bg-light-gray {
	background-color: #eaeaea;
}
