.tour-details {
	p {
		margin-bottom: 0 !important;
	}
	.tour-booking-item {
		position: relative;
		margin: 1rem 0;
		padding: 0.8rem;
		padding-bottom: 0;
		border: 1px solid #ebebeb;
	}
	.tour-booking-item i {
		font-size: 13px;
		color: #cb9a51;
	}
	.tour-booking-item-info {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin: 2px 0;
		i {
			color: #cb9a51;
		}
	}
	.tour-delete-icon {
		position: absolute;
		top: 8px;
		inset-inline-end: 8px;
	}
	.tour-booking-item-warning {
		font-size: 14px;
		color: #e39633;
	}
	.tour-booking-item-blue-dark {
		font-size: 14px;
		color: #202020;
		line-height: 1.2;
	}
	.tour-booking-item-cancelation {
		position: relative;
		display: flex;
		flex-direction: column;
		border-top: 1px solid #ebebeb;
		font-size: 14px !important;
	}

	.tour-booking-item-cancelation::after {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 2px;
		height: 100%;
		background-color: #ebebeb;
	}

	.tour-booking-item-cancelation [class*="col-"]:first-child {
		padding-right: 15px;
		padding-left: 0;
	}

	.tour-booking-item-cancelation [class*="col-"] {
		padding-left: 15px;
	}

	[dir="rtl"] .tour-booking-item-cancelation [class*="col-"]:first-child {
		padding-left: 15px;
		padding-right: 0;
	}
	[dir="rtl"] .tour-booking-item-cancelation [class*="col-"] {
		padding-left: 0;
		padding-right: 15px;
	}
	.tour-card-img {
		height: 150px;
		flex-basis: 220px;
		flex-grow: 0;
		flex-shrink: 0;
		overflow: hidden;
	}
	.tour-details-actions {
		button {
			border: 1px solid #d29d4d !important;
			color: #d29d4d;
		}
	}
	.btn-accept {
		background-color: #fff;
		border: 1px solid #00b545 !important;
	}
	.btn-reject {
		background-color: #fff;
		border: 1px solid #dc3545 !important;
	}
}

.model-cancle {
	max-width: 600px !important;
	.tour-booking-item-info {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin: 2px 0;
		i {
			color: #cb9a51;
		}
	}
	.tour-card-img {
		height: 110px;
		flex-basis: 150px;
		flex-grow: 0;
		flex-shrink: 0;
		overflow: hidden;
	}
	.tour-card-img img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
}
.text-dark-blue {
	color: #0c3b5c !important;
}
.text-light-warning {
	color: #d29d4d !important;
}
.text-light-danger {
	color: #c30101 !important;
}
.bg-light-gray {
	background-color: #f3f3f3 !important;
}
.light-border {
	border: 1px solid #ebebeb;
}
.main-filter-sold-visa {
	.col .control-field__body {
		padding: 0.625rem 0.625rem 0.3125rem 0rem !important;
	}
}
