.bg-subNav {
	background-color: #e5e5e5;
	padding: 10px;
	color: #a7afb7;
}

.active-companies-tourism {
	border-top: 2px solid #57c960;
	background-color: #e5e5e5;
	padding: 10px;
	color: #354f5c;
	position: relative;
	&:before {
		content: "";
		transform: rotate(62deg);
		position: absolute;
		bottom: -2px;
		position: absolute;
		left: 44%;
		width: 0px;
		height: 0px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 8px solid #ffffff;
	}
}
.w-15 {
	width: 15%;
}

.shape-border {
	border: 2px solid #91969b;
	width: 15%;
}

.companies-btn {
	background-color: #354f5c;
	color: white;
	padding: 10px;
	width: 15%;
	border-radius: 0;
}

.collapse-border {
	background-color: #d6d7dd;
	border: 1px solid #a7afb7 !important;
	color: #313533;
	p {
		margin-bottom: 0px !important;
	}
}

.collapse-table-border {
	border: 1px solid #a7afb7 !important;
	border-top: 0 !important;
}

.circly {
	background-color: #354f5c;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	padding: 3px;
	color: white;
}

.file-btn {
	background-color: #00aaeb;
	position: relative;
	border-radius: 5px;
	color: white;
	font-weight: 500;
	border: none;
	width: 10%;
	padding: 3px;
	font-size: 14px;
	box-shadow: #00aaeb 0px 3px 6px;
	cursor: pointer;
}
.custom-file {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
}
.bg-information {
	background-color: #c8c8c8;
}
.wallet-box {
	border: 1px solid #d6d7dd;
}
.wallet-btns {
	overflow: hidden !important;
	border: 1px solid #d3d7db;
	.bg-wallet-btn {
		background-color: #cb9a51;
		color: white;
	}
}
.bg-wallet-name {
	background-color: #313533;
	transition: 0.5s;
	color: white;
}

.trash:hover {
	color: rgb(231, 28, 28);
	transition: 0.3s;
	cursor: pointer;
}
.trash {
	font-size: 20px;
	transition: 0.3s;
	color: #a7afb7;
}

.text-gray {
	color: #a7afb7 !important;
}
/** tabs **/
.tabs {
	background-color: #fff !important;

	.bg-subNav {
		padding: 15px 30px;
		border: 1px solid #dfdfdf;
	}
	.bg-subNav.active-companies-tourism {
		background-color: #fff;
		font-weight: bold;
		border-bottom: 1px solid #fff;
	}
	/* .active-companies-tourism:before {
			content: "";
			transform: rotate(180deg);
			position: absolute;
			bottom: -2px;
			position: absolute;
			left: 44%;
			width: 0px;
			height: 0px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 11px solid #ebebeb;
		}
 */
	.tab-content {
		min-height: 84vh;
	}
	.nav {
		display: flex;
		flex-wrap: nowrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		.nav-item {
			min-height: 55px;
			width: 20%;
			text-align: center;
			margin-bottom: 0px !important;
			background: #ebebeb !important;
		}
		.nav-link.active {
			height: 55px !important;
		}
		.nav-link {
			height: 55px !important;
			border: 0.3px solid #dfdfdf;
			border-bottom: 0;
			border-radius: 0 !important;
		}
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-color: #fff;
		min-height: 55px !important;
		background-color: #fff !important;
		border-radius: 0 !important;
		border: 0.5px solid #dfdfdf;
		border-bottom: 0;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active .product-build-list-item span {
		color: #cb9a51 !important;
		font-size: 18px;
		font-weight: 700;
	}

	.tab-icons {
		background-color: #ebebeb !important;
	}

	.no-hotel {
		width: 100%;
		background: #f7f7f7;
		height: 350px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 0.3125rem;
		p {
			color: #979797;
			margin: 15px 0;
		}
	}
	.btn-yallow {
		background-color: #cb9a51 !important;
		color: #fff;
		font-size: 16px;
	}
	.select-hr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			color: #979797;
			font-size: 15px;
		}
		div {
			height: 1px;
			width: 88%;
			background: #dfdfdf;
		}
	}
}
.companies-subscription {
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 16px;
		h4 {
			color: rgba(0, 0, 0, 1);
			font-size: 20px;
			font-weight: 700;
		}
		button {
			padding: 8px 16px 8px 16px;
			font-size: 14px;
			font-weight: 500;
			box-shadow: none !important;
		}
	}
	.subscription-table {
		border: 1px solid rgba(221, 220, 220, 1);
		border-radius: 8px;
		margin-top: 12px;
		overflow: hidden;
		.table {
			margin-bottom: 0 !important;
			thead {
				background-color: rgba(247, 246, 246, 1);
				height: auto;
				th {
					color: rgba(75, 70, 92, 1);
					font-size: 13px;
					font-weight: 700;
					padding: 10px;
					letter-spacing: 1px;
					text-transform: uppercase;
				}
			}
			tbody {
				background-color: #fff;
				td {
					color: rgba(5, 0, 1, 1);
					font-size: 15px;
					font-weight: 500;
				}
				.amount-td {
					font-size: 16px;
					font-weight: 600;
				}
			}
			.action {
				display: flex;
				gap: 8px;
			}
		}
		.paid {
			border: 1px solid rgba(1, 157, 76, 1);
			color: rgba(1, 157, 76, 1);
			background-color: rgba(242, 255, 248, 1);
			text-align: center;
			border-radius: 8px;
			padding: 4px 15px;
			font-size: 14px;
			text-transform: capitalize;
		}
		.pending {
			border: 1px solid rgba(255, 159, 67, 1);
			color: rgba(255, 159, 67, 1);
			background-color: rgba(255, 251, 246, 1);
			text-align: center;
			border-radius: 8px;
			padding: 4px 15px;
			font-size: 14px;
			text-transform: capitalize;
		}
		.canceled {
			border: 1px solid rgba(234, 84, 85, 1);
			color: rgba(234, 84, 85, 1);
			background-color: rgba(255, 249, 249, 1);
			text-align: center;
			border-radius: 8px;
			padding: 4px 15px;
			font-size: 14px;
			text-transform: capitalize;
		}
		.free {
			border: 1px solid rgba(221, 220, 220, 1);
			color: rgba(150, 150, 150, 1);
			background-color: rgba(221, 220, 220, 0.1);
			text-align: center;
			border-radius: 8px;
			padding: 4px 15px;
			font-size: 14px;
			text-transform: capitalize;
		}
		.expired {
			border: 1px solid rgba(112, 113, 112, 1);
			color: rgba(112, 113, 112, 1) !important;
			background-color: #f1f1f1f1;
			text-align: center;
			border-radius: 8px;
			padding: 4px 15px;
			font-size: 14px;
			text-transform: capitalize;
		}
	}
}
.subscription-commission {
	background-color: rgb(255, 255, 255);
	padding: 20px;
	border-radius: 8px;
	border: 1px solid rgb(247, 246, 246);
	.head {
		display: flex;
		justify-content: space-between;
		h6 {
			color: rgba(5, 0, 1, 1);
			font-size: 20px;
			font-weight: 700;
		}
		button {
			box-shadow: none;
		}
	}
	.services-list {
		margin-top: 15px;
		.service-head {
			background-color: rgba(248, 249, 250, 1);
			border-bottom: 1px solid rgba(231, 231, 231, 1);
			p {
				color: rgba(5, 0, 1, 1);
				font-size: 18px;
				font-weight: 700;
				padding: 10px;
			}
		}
		.service-item {
			margin-top: 12px;
			border-bottom: 1px solid rgba(233, 233, 239, 1);
			padding-bottom: 10px;

			.name-item {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.info {
					display: flex;
					gap: 8px;
					input[type="checkbox"] {
						width: 17px !important;
					}

					div {
						display: flex;
						align-items: center;
						gap: 5px;
						svg {
							width: 45px;
						}
						p {
							color: rgba(112, 113, 112, 1);
							font-size: 16px;
							font-weight: 700;
							text-transform: capitalize;
						}
					}
				}
			}
			.providers {
				margin-top: 15px;
				.labels {
					.provider-col {
						p {
							width: 70%;
						}
					}
					p {
						background-color: rgba(237, 239, 241, 1);
						padding: 10px 0;
						border-radius: 4px;
						text-align: center;
						font-size: 15px;
						font-weight: 600;
						color: rgba(5, 0, 1, 1);
					}
				}
				.info-item {
					padding-block: 15px;
					// border-bottom: 1px solid rgba(233, 233, 239, 1);
					&:last-of-type {
						border-bottom: none !important;
					}
					.gds-select {
						width: 70%;
						.control-field {
							margin: 0 !important;
						}
						.control-field,
						.control-field__body,
						.no-gutters,
						.control-field__select {
							width: 100%;
						}
					}
					.provider-col {
						display: flex;
						align-items: center;
						.input-commission {
							width: 70%;
							display: inline-block;
							text-align: center;
							p {
								font-size: 14px;
								font-weight: 600;
							}
						}
						input[type="checkbox"] {
							width: 17px !important;
							height: 16px;
						}
					}
					.input-commission {
						margin: 5px 0;
						input {
							background: rgba(194, 200, 208, 0.3);
							border: 1px solid rgb(221 221 221);
							padding: 8px;
							border-radius: 5px;
							width: 100%;
						}
						.control-field__label {
							display: none;
						}
						.input-group {
							margin: 0 !important;
						}
						.control-field__body {
							padding: 0 !important;
						}
					}
					.percentage {
						vertical-align: top;
						display: flex;
						align-items: center;
					}
				}
				.add-new-commission {
					display: flex;
					justify-content: end;
					width: 83%;
					button {
						font-size: 16px;
						font-weight: 400;
						text-decoration: unset !important;
					}
				}
			}
		}
	}
}
.info-plan {
	background-color: #00aaeb;
	.modal-content {
		padding: 8px;
		width: 650px;
	}
	.modal-header {
		border-bottom: 1px solid rgba(221, 220, 220, 1) !important;
		padding-top: 8px !important;
		padding-bottom: 5px !important;
		.modal-title {
			width: 100%;
			font-size: 18px;
			font-weight: 600;
			text-align: center;
		}
		button {
			background: transparent;
			font-size: 21px;
		}
	}
	.info-plan-model {
		.module-title {
			margin-top: 16px;
			padding: 5px 0;
			border-bottom: 1px solid #dddcdc;
			display: flex;
			justify-content: space-between;
			p {
				color: rgba(5, 0, 1, 1);
				font-size: 18px;
				font-weight: 500;
			}
			.price {
				input {
					// width: 90px;
					text-align: center;
					margin-right: 5px;
					border: 1px solid rgba(193, 193, 193, 1);
					border-radius: 4px;
					padding: 5px 0px 6px 0px;
					text-align: center;
					background-color: rgba(194, 200, 208, 0.3);
				}
			}
		}
		.sub-module {
			.sub-module-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0;
				border-bottom: 1px solid #dddcdc;

				p {
					color: rgba(112, 113, 112, 1);
					font-size: 16px;
					font-weight: 500;
					margin-left: 30px;
				}
				.price {
					input {
						border: 1px solid rgba(193, 193, 193, 1);
						border-radius: 4px;
						padding: 5px 0px 6px 0px;
						text-align: center;
						background-color: rgba(194, 200, 208, 0.3);
					}

					span {
						color: rgba(44, 48, 46, 1);
						font-size: 14px;
						font-weight: 400;
						margin-left: 5px;
					}
				}
			}
		}
		.free-text {
			border: 1px solid rgba(1, 157, 76, 1);
			border-radius: 4px;
			padding: 5px 0px 6px 0px;
			text-align: center;
			background-color: rgba(194, 200, 208, 0.3);
			color: rgba(1, 157, 76, 1);
			padding: 3px 50px;
			font-size: 16px;
		}
	}
}
.change-status-plan-model {
	.modal-content {
		padding: 18px;
	}
	.modal-header {
		padding-top: 0 !important;
		img {
			margin-top: 15px;
		}
		button {
			background-color: transparent;
			font-size: 25px;
		}
	}
	.modal-body {
		.title {
			margin-top: 15px;
			h6 {
				color: rgba(16, 24, 40, 1);
				font-size: 18px;
				font-weight: 600;
			}
			p {
				color: rgba(112, 113, 112, 1);
				font-size: 16px;
				font-weight: 400;
				margin-top: 8px;
			}
		}
		.input {
			margin-top: 32px;
			label {
				color: #707170;
				font-size: 16px;
				font-weight: 400;
				display: block;
			}
			input {
				padding: 10px;
				border-radius: 6px;
				border: 1px solid #dddcdc;
				font-size: 15px;
				width: 100%;
				&::placeholder {
					color: rgba(172, 172, 172, 1);
				}
			}
			button {
				background-color: rgba(30, 133, 255, 1);
				border-radius: 6px;
				padding: 12px 16px 12px 16px;
				color: #fff;
				font-size: 18px;
				font-weight: 500;
				width: 100%;
				margin-top: 12px;
			}
		}
	}
}
.add-edit-plan {
	margin-bottom: 25px !important;
	.apply-free {
		margin-top: 20px;
		border: 1px solid rgba(226, 227, 234, 1);
		border-radius: 6px;
		padding: 16px;
		background-color: rgba(247, 247, 247, 1);
		.dates {
			display: flex;
			align-items: center;
			gap: 8px;
			p {
				color: rgba(112, 113, 112, 1);
				font-size: 14px;
				font-weight: 400;
			}
			.btn-apply {
				background-color: rgba(79, 195, 247, 1);
				border-radius: 6px;
				padding: 6px 22px 6px 22px;
				color: #fff;
				font-size: 15px;
				font-weight: 500;
			}
			.control-field__body {
				padding: 0.425rem 0.625rem 0.425rem 0.525rem !important;
			}
		}
	}
}
.action-add-subscription-plan {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	padding: 16px;
	p {
		color: rgba(112, 113, 112, 1);
		font-size: 14px;
		font-weight: 400;
	}
	.control-field__body {
		padding: 0.425rem 0.625rem 0.425rem 0.525rem !important;
	}
}
.btn-action-subsc-plan {
	display: flex;
	flex-direction: row-reverse;
	gap: 15px;
	margin-top: 25px;
	.btn-cancel {
		background-color: rgba(234, 84, 85, 1);
		border: 1px solid rgba(215, 26, 33, 1);
		border-radius: 6px;
		color: #fff;
		padding: 12px 56px 12px 56px;
	}
	.btn-confirm {
		background-color: rgba(1, 157, 76, 1);
		border: 1px solid rgba(4, 138, 69, 1);
		border-radius: 6px;
		color: #fff;
		padding: 12px 56px 12px 56px;
	}
}

.sub-tabs {
	.nav-link {
		display: flex;
		align-items: center;
		background: #ebebeb;
		color: #222;
		border: 1px solid #ebebeb;
	}
	.nav-link.active {
		color: #fff !important;
		display: flex;
		border-radius: 0 !important;
		align-items: center;
	}
}
.semiHeight {
	height: 35px !important;
}
.btn-link {
	color: #333333 !important;
	text-decoration: underline !important;
	font-size: 16px;
}
.w-90p {
	width: 90px;
}
.text-green {
	color: rgba(1, 157, 76, 1);
	text-transform: capitalize;
}
.text-dangr {
	color: rgba(244, 15, 15, 1) !important;
	text-transform: capitalize;
}
