.airline-details {
	font-family: "Cairo";
	.sum-type {
		background-color: #e5e5e5;
		color: #979797;
		border-radius: 5px;
		padding: 5px;
		font-weight: bold;
	}
	i {
		color: #bf7f1a;
	}

	.flight_item {
		background-color: #fff;
		width: 100%;
		p {
			margin-bottom: 0 !important ;
		}
		.head-toggel {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			font-size: 14px;
			padding: 4px;

			a {
				color: #2d2d2d;

				i {
					margin-left: 4px;
				}
			}
		}

		.time-line {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			column-gap: 10px;
			padding-bottom: 10px;

			.infoAir {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				position: relative;

				.takeoff::after {
					content: "";
					position: absolute;
					padding: 3px;
					border: 1px solid #1a61af;
					border-radius: 50%;
					top: 25%;
					[dir="ltr"] & {
						right: -11px;
					}
					[dir="rtl"] & {
						left: -11px;
					}
				}
			}

			.Landing {
				display: flex;
				flex-direction: column;
				position: relative;
				align-items: flex-start;

				.takeoff::before {
					content: "";
					position: absolute;
					padding: 3px;
					border: 1px solid #1a61af;
					border-radius: 50%;
					top: 25%;
					[dir="ltr"] & {
						left: -11px;
					}
					[dir="rtl"] & {
						right: -11px;
					}
				}
			}

			.airport-name {
				color: #2d2d2d;
				font-size: 14px;
			}
		}

		.footer-toogle {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 5px 0;
			justify-content: space-between;
			border-top: 1px solid #ebebeb;

			.img-company {
				width: 50px;
				height: 50px;
			}
		}

		.spent {
			color: #a0a0a0;
			font-size: 14px;
			padding: 5px 20px 16px 20px;
			border-top: 2px dashed #1a61af;
		}
		.collapse-box {
			.spent {
				padding: 5px 20px 25px 20px !important;
			}
		}
		.img-company {
			width: 50px;
			height: 50px;
		}
		.stop-box {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: -16px;
				top: -62px;
				border: 1px solid #b1b1b1;
				padding: 3px;
				border-radius: 50%;
			}

			&::before {
				content: "";
				position: absolute;
				left: -16px;
				bottom: -82px;
				border: 1px solid #b1b1b1;
				padding: 3px;
				border-radius: 50%;
			}

			.blue {
				color: #1a61af;
				&::after {
					content: "";
					position: absolute;
					left: -18px;
					top: 20%;
					border: 1px solid #0372bc;
					padding: 5px;
					border-radius: 50%;
				}

				&::before {
					content: "";
					position: absolute;
					top: 20px;
					left: -87px;
					width: 150px;
					border-bottom: 2px dashed #979797;
					transform: rotate(270deg);
				}
			}
			.stop {
				color: #a0a0a0;
				font-size: 14px;
				padding: 5px 20px 0px 20px;
			}
		}

		.blue {
			color: #1a61af;
		}
	}
	.form-head {
		background-color: #e4e4e4;
		color: #0f3e5e;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		border-radius: 11px 11px 0 0 !important;
	}
	.air-summary {
		h6 {
			color: #0f3e5e;
			font-weight: 300;
		}
		.total-price {
			background-color: #fff;
			padding: 8px;
			p {
				color: #0f3e5e !important;
				font-weight: 700;
				margin-bottom: 0;
			}
		}
	}
	.border-light {
		border: 1px solid #e9e9f0 !important;
	}
	.adults-price-summary {
		p {
			margin-bottom: 0 !important;
		}
		.price-color {
			color: #8e8e8e;
		}
	}
}

.bg-light-danger {
	background-color: #faf2e8;
}
.text-custome {
	color: #cb9a51 !important;
}

.btn-x {
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	background-color: #d1d6dd;
	border-radius: 5px;
	padding: 4px 8px;
}
.table-update {
	tbody tr:nth-of-type(odd) {
		background-color: rgba(0, 0, 0, 0.01);
	}
	thead th {
		border-bottom: 0 !important;
		color: #0c3b5c;
		font-size: 15px;
		font-weight: 700;
		line-height: 1.2;
	}
	tbody tr:hover {
		background-color: #faf2e8 !important;
	}
	tbody td {
		color: #464646;
		font-size: 13px;
		font-weight: 600;
	}
	.bg-status {
		background: #28a745;
		border-radius: 5px;
		color: #fff;
	}
	.actions {
		i {
			color: #464646;
		}
	}
}

.fare-rules-model {
	.rules-side-bar {
		max-height: 50vh;
		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
		}
		ul {
			padding: 10px 0px 10px 0px;
			li {
				color: #808080;
				font-size: 14px;
				padding-left: 20px;
			}
			li:hover {
				background-color: #ebf5ff;
				border-left: 3px solid #0050a6;
				color: #0050a6;
			}
			li.active {
				background-color: #ebf5ff;
				border-left: 3px solid #0050a6;
				color: #0050a6;
			}
		}
	}
	.bg-tap {
		border: 1px solid #e1e1e1;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		height: 50px;
		background-color: #f7f7f7;
	}
	.rules-tap {
		border: 1px solid #e1e1e1;
		display: flex;
		align-items: center;
		padding: 8px;
	}
	.rules-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
	}
	.title-rules {
		display: flex;
		flex-direction: column;
		p {
			font-size: 14px;
		}
		span {
			font-size: 10px;
			line-height: 1;
		}
	}
	.rules-side-bar {
		td,
		th {
			padding: 10px;
		}
		.table-container {
			border-radius: 5px;
			overflow: hidden;
		}
	}
	.rules-side-bar {
		&::-webkit-scrollbar {
			width: 10px;
		}

		&::-webkit-scrollbar-track {
			background: #fff;
			border: 1px solid #eeee;
		}

		&::-webkit-scrollbar-thumb {
			background: #dadada;
			border-radius: 20px;
			border: 1px solid #eeee;
		}

		// &::-webkit-scrollbar-thumb:hover {
		//   background: #555;
		// }
	}
}
/** Start Request style**/
.flight-requests-header {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	border: 1px solid #ededed;
	& .btn-toggel:focus,
	.btn-toggel:focus-visible {
		outline: none;
		border: 0;
		box-shadow: none;
	}
	.request-reseration-num {
		width: 125px;
	}
	.request-status {
		width: 155px;
	}
	// & p {
	// 	min-width: 190px;
	// }
}
.flight-requests-type {
	display: flex;
	align-items: center;
	width: 140px;
	background-color: #f8f9fa;
	color: #10365f;
	border: 1px solid #ededed;
	font-weight: bold;
}
.flight-requests-details {
	position: relative;
	background-color: #f8f9fa;
	border: 1px solid #ededed;
	padding: 12px;
}
.flight-requests-box {
	background-color: #f4f4f4;
	border: 1px solid #ededed;
	border-radius: 3px;
	word-break: break-word;
}
.flight-requests-danger-box {
	background-color: #c3010114;
	border: 1px solid #ededed;
	border-radius: 3px;
	word-break: break-word;
}
.flight-requests-danger-box :is(h1, h2, h3, h4, h5, h6) {
	color: #c30101;
	font-weight: bold;
}

.flight-requests-change-passenger {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	// width: 95%;
	padding: 8px 6px;
	margin: 3px 0;
	background-color: #00b54517;
	border: 1px solid #ededed;
	border-radius: 3px;
	// overflow: hidden;
	.text-danger {
		color: #dc3545 !important;
	}
}
.flight-requests-reject-box {
	margin-top: 8px;
	padding: 8px;
	background: #c3010114;
	.text-danger {
		color: #dc3545 !important;
	}
}

.flight-requests-refund-passenger {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	// width: 95%;
	padding: 8px 6px;
	margin: 3px 0;
	background-color: #f4f4f4;
	border: 1px solid #ededed;
	border-radius: 3px;
	overflow: hidden;
}
.flight-requests-contact {
	padding: 0 18px;
	margin: 3px 0;
	background-color: #f4f4f4;
	border: 1px solid #ededed;
	border-radius: 3px;
}

.flight-requests-refund-amount {
	// background-color: #daa24927;
	// border: 1px solid #ededed;
	border-radius: 3px;
}
.flight-requests-refund-amount h3 {
	color: #027b30;
}
.flight-requests-refund-actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.accept-btn {
	padding: 8px 2.6rem;
	background-color: #00b680;
	border-radius: 3px;
	box-shadow: 0px 3px 6px #00b68053;
	color: #fff;
}
.reject-btn {
	padding: 8px 2.6rem;
	background-color: #ee2548;
	border-radius: 3px;
	box-shadow: 0px 3px 6px #ee254853;
	color: #fff;
}
.reject-btn:disabled,
.accept-btn:disabled {
	box-shadow: none;
	background-color: #d1d6dd;
	color: #000;
	opacity: 0.55;
	cursor: not-allowed;
}

.download-cert {
	margin: 0;
	background-color: transparent;
	color: #d7a04b;
	font-weight: bold;
	cursor: pointer;
}

.blue {
	color: #1a61af !important;
}

.fees-currency {
	position: absolute;
	top: 65%;
	right: 0;
	width: 100px;
	transform: translateY(-50%);
	z-index: 9;
}
.fees-currency .control-field__select {
	background-color: #d1d6dd;
}
.fees-currency svg {
	color: #5f5f5f;
	fill: #5f5f5f;
}
.text-warning-status {
	color: #d29d4d;
}
.text-danger-status {
	color: #ee2548;
}
.text-success-status {
	color: #00b680;
}

.change-request-time-picker-container {
	text-align: start;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	align-content: flex-end;
	padding-inline: 15px !important;
}
.change-request-time-picker {
	fieldset {
		height: 35px;
	}
}

.change-request-fields {
	.control-field__body {
		padding-top: 0;
	}
}
.change-request-fields.flight-time {
	.control-field__append {
		span {
			color: #7c929a !important;
			font-size: 14px !important;
		}
	}
}
.route-filed {
	.control-field__label {
		padding-inline: 10px;
	}
	.control-field__body {
		padding-top: 0;
	}
}

.segment-header {
	background-color: #354f5c;
	padding: 3px;
	position: relative;
	top: 13px;
	color: white;
	border-radius: 3px;
}

.old-value {
	position: relative;
	span {
		display: none;
	}
	i {
		color: #d60429;
		margin-inline-start: 4px;
	}
}
.old-value:hover {
	span {
		display: block;
		background-color: #d60429;
		padding: 2px 5px;
		color: white;
		position: absolute;
		inset-block-start: -24px;
		font-size: 12px;
		inset-inline-start: 0;
		border-radius: 3px;
		white-space: nowrap;
		z-index: 1;
	}
}
