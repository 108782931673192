#reserv-history {
	.main_history .history_block .tes:after {
		content: "";
		position: absolute;
		top: 30px;
		left: 14px;
		height: 100%;
		border: 1px dashed #2c302e !important;
		z-index: 0;
	}

	.main_history .history_block:last-child .tes:after {
		display: none;
	}

	.history_block {
		position: relative;

		h6 {
			color: #354f5c !important;
			margin-bottom: 0.2rem;
		}
		p {
			margin: 0;
			color: #919191;
		}
	}
	.history_block > div {
		align-items: center;
		padding-top: 8px;
	}

	.history_content {
		border-bottom: 1px solid #e1e2e3;
		flex-grow: 1;
	}
	.history_filter {
		&_select .control-field__body {
			padding: 0;
		}
		&_date .control-field__body {
			padding-bottom: 0.5125rem;
		}
	}
}
