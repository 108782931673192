.hotel-box {
	background: #e5e5e5;
	padding: 1rem;
	.head {
		display: flex;
		align-items: center;
		.title {
			font-weight: 700;
			color: #324356;
		}
		.bookable {
			background: #00b437;
			color: #fff;
			padding: 0.1rem 0.5rem;
			border-radius: 3px;
			font-size: 12px;
			overflow: hidden;
			margin: 0 0.5rem;
		}
	}
}

.link-toggle {
	width: 217px !important;
}
.transfer {
	.btn-invoice {
		background: #fff;
		border: 1px solid #d29d4d !important;
		color: #d29d4d !important;
		padding: 4px 6px !important;
	}
	.btn-invoice-vendor {
		font-size: 16px;
    background: #d29d4d;
    color: #fff !important;
    font-weight: 700;
    padding: 6px 14px !important;
	}
	.box-invoice-btn {
		display: flex;
		align-items: center;
		justify-content: end;
		column-gap: 10px;
	}
	.box-info {
		width: 100%;
		background-color: #faf2e8;
		border: 1px solid #ededed;
		color: #000000;
		display: flex;
		align-items: center;
		justify-content: space-around;
		height: 65px;
		padding: 0xp 10px !important;
		p {
			margin: 0px;
		}
	}
	.trans-dark {
		color: #0f3e5e !important;
		font-weight: bold;
	}
	.trans-dark-top{
		font-size: 18px;
    font-weight: 700;
    border-left: 3px solid #d29d4d ;
    padding: 5px;
    margin-bottom: 10px;
		color: #0c3b5c;
	}
	.ref-btn{

    border: 1px solid #d29d4d;
    border-radius: 5px;
    background-color: #ffff;
    padding: 10px;
    color: #d29d4d;
    margin-bottom: 10px;

	}
	.trans-dark-light {
		color: #0f3e5e !important;
		font-weight: lighter;
		font-size: 16px;
		padding: 5px 0px;
	}
	.transfer-item {
		background-color: #fff;
		border: 1px solid #ebebeb;
	}
	.half-box {
		width: 50%;
		padding: 20px;
	}
	.two-box {
		display: flex;
		border: 1px solid #ebebeb !important;
		border-left: 0px !important;
		border-right: 0px !important;
		width: 100%;
	}
	.info-trans {
		p {
			margin-bottom: 5px;
			i {
				color: #d29d4d;
				margin-right: 4px;
			}
		}
		font-weight: bold;
	}
	.text-warning {
		color: #d29d4d !important;
	}
	p {
		margin-bottom: 0px;
	}
	.col-price {
		background-color: #fff;
		border: 1px solid #ebebeb;
		padding: 10px 20px;
	}
	.item-box {
		border-bottom: 1px solid #ebebeb;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}
	.item-price {
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.total-price {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px;
		font-size: large;
	}
	.img-box{
		border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;	
	}
	.model-row{
		border-top: 1px solid #eee;
		text-align: end;

	}
	.cancel-btn{
		background-color: #D00C2E;
		color: #fff;
	}
}
.Booking-Details-tab{
	background-color: #fff;
	color: #d29d4d;
	font-size: 16px;
	font-weight: 700;
	padding: 10px;
	border: 1px solid #eee;
	border-bottom: none;
}