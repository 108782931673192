.country-configuration-container {
	.country-title {
		font-size: 22px;
		color: #4b465c;
	}
	.country-header {
		.filter-input {
			background-color: white;
			display: flex;
			max-width: 308px;
			gap: 8px;
			padding: 8px;
			border: 1px solid #dddcdc;
			border-radius: 8px;

			input {
				border: none;
			}
		}
	}
	.country-list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
		margin-top: 26px;
	}
	.country-card {
		background-color: white;
		border-radius: 12px;
		border: 1px solid #dddcdc;
		padding: 20px;
		display: flex;
		flex-direction: column;
		p {
			font-size: 18px;
		}
		img {
			align-self: center;
			margin-block: 24px;
		}
		.replace-image {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;
			span {
				color: #d4a655;
			}
		}
	}
}

.country-configuration-modal {
	.country-name {
		font-weight: bold;
	}
	&-header {
		display: flex;
		position: relative;
		justify-content: center;
		p {
			font-size: 18px;
			margin: 0;
		}
	}
	&-body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&-upload {
		width: 100%;
		margin-top: 10px;
		label {
			padding: 8px;
			background-color: #faf4eb;
			border: 1px dashed #eed9ba;
			color: #d4a655;
			display: flex;
			gap: 8px;
			justify-content: center;
			align-items: center;
			border-radius: 6px;
			cursor: pointer;
		}
	}
	&-input {
		display: none;
	}
	&-edit {
		background-color: #d4a655;
		border-radius: 8px;
		width: 100%;
		color: white;
		padding: 12px;
	}
	.close-icon {
		background-color: transparent;
		position: absolute;
		inset-inline-end: 0;
	}
}
