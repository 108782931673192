// .h-75-legend{
//   display:none
// }
.main {
	position: relative;
}
.tes {
	// position:relative;
	&::after {
		position: absolute;
		top: 35px;
		left: 62.5px;
		height: 100%;
		content: "";
		border: 1px dashed #24aefb !important;
		z-index: 0;
	}
}

.main:last-child .tes::after {
	border: none !important;
}
