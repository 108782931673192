.suppliers {
	.img-service div {
		display: inline-flex;
		background-color: #deeaf9;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
	}
	.back-office-table td,
	.back-office-table th {
		padding: 3px !important;
	}
	.back-office-table th {
		color: #313533 !important;
	}
}
.edit-supplier {
	h3 {
		line-height: 2;
		color: #354f5c;
		font-size: 19px;
		font-weight: 700;
	}
	.services {
		.services-header {
			background-color: #c8c8c8;
			padding: 3px 7px;
		}
		.services-items {
			.services-item-content {
				background-color: #e5e5e5;
				border: 1px solid #c6cbd0;
				padding: 5px 15px;
				height: 70px;
			}
			.service-img {
				background-color: #deeaf9;
				width: 65px !important;
				height: 65px !important;
				border-radius: 50%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
			}
			.service-name {
				color: #354f5c;
				font-size: 19px;
				font-weight: 700;
			}
		}
	}
	.inputs {
		.control-field__body {
			background-color: #e5e5e5 !important;
			border: 1px solid #c6cbd0 !important;
			border-radius: 0 !important;
		}
		.isDisabled input[type="text"]:disabled {
			background: #e5e5e5;
			font-size: 18px !important;
		}
		.control-field__label {
			font-size: 14px !important;
		}
	}
}
.btn-grey-suppliers {
	background-color: #354f5c !important;
	color: #fff !important;
}
