@font-face {
	font-family: "#{$icomoon-font-family}";
	src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?g1f08b")
			format("truetype"),
		url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g1f08b")
			format("woff"),
		url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?g1f08b##{$icomoon-font-family}")
			format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.icx {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "#{$icomoon-font-family}" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icx-arrow-right {
	&:before {
		content: var(--icx-arrow-right);
	}
}
.icx-bell {
	&:before {
		content: $icx-bell;
	}
}
.icx-financail-payment {
	&:before {
		content: $icx-financail-payment;
	}
}
.icx-comment {
	&:before {
		content: $icx-comment;
	}
}
.icx-camera-location {
	&:before {
		content: $icx-camera-location;
	}
}
.icx-edit {
	&:before {
		content: $icx-edit;
	}
}
.icx-hotel {
	&:before {
		content: $icx-hotel;
	}
}
.icx-map {
	&:before {
		content: $icx-map;
	}
}
.icx-itinerary {
	&:before {
		content: $icx-itinerary;
	}
}
.icx-moon {
	&:before {
		content: $icx-moon;
	}
}
.icx-plane {
	&:before {
		content: $icx-plane;
	}
}
.icx-share {
	&:before {
		content: $icx-share;
	}
}
.icx-star {
	&:before {
		content: $icx-star;
	}
}
.icx-transport {
	&:before {
		content: $icx-transport;
	}
}
.icx-trash {
	&:before {
		content: $icx-trash;
	}
}
.icx-eye-slash {
	&:before {
		content: $icx-eye-slash;
	}
}
.icx-building {
	&:before {
		content: $icx-building;
	}
}
.icx-eye {
	&:before {
		content: $icx-eye;
	}
}
.icx-images {
	&:before {
		content: $icx-images;
	}
}
.icx-left-solid-arrow {
	&:before {
		content: var(--icx-left-solid-arrow);
	}
}
.icx-right-solid-arrow {
	&:before {
		content: var(--icx-right-solid-arrow);
	}
}
.icx-shield {
	&:before {
		content: $icx-shield;
	}
}
.icx-user {
	&:before {
		content: $icx-user;
	}
}
.custom--row {
	margin-right: -5px !important;
	margin-left: -5px !important;
}
.btn-sbs-primary {
	font-size: 1rem !important;
	color: #ffffff;
	background-color: #354f5c;
	border-color: #354f5c;
	border-radius: 3px;
}
.btn-sbs-primary:hover {
	color: #ffffff;
	background-color: #37525f;
	border-color: #37525f;
}
.btn-sbs-secondary {
	font-size: 1rem !important;
	color: #ffffff;
	background-color: #8d9399;
	border-color: #8d9399;
	border-radius: 3px;
}
.btn-sbs-secondary:hover {
	color: #ffffff;
	background-color: #888888;
	border-color: #888888;
}
.btn-link {
	color: #354f5c !important;
}
.item--display {
	padding: 0.5rem;
	.item--key {
		color: #454545;
		font-size: 0.9rem !important;
	}
	.item--value {
		color: #354f5c;
		font-size: 1.125rem;
		font-weight: 500;
	}
}
.light-bg {
	background-color: #e5e5e5;
	border: 1px solid #c6cbd0;
}

.edit-selector-btn {
	position: absolute;
	right: 20px;
	left: auto;
	top: 50%;
	transform: translateY(-50%);
}
[dir="rtl"] .edit-selector-btn {
	left: 20px;
	right: auto;
	transform: translateY(-50%);
}
[dir="rtl"] .modal-content {
	direction: rtl;
	text-align: right;
}
.btn-lg,
.btn-group-lg > .btn {
	font-size: 1.25rem !important;
}
.text-orange {
	color: #dc7542 !important;
}
.text-default {
	color: #354f5c !important;
}

.text-decoration-underlined {
	text-decoration: underline !important;
}

//----------------------- custom range slider style --------------------------------------------
.custom-range-slider {
	.multi-range-slider {
		border: 0;
		border-radius: 0;
		padding: 8px 4px;
		box-shadow: none;
	}
	.multi-range-slider .bar-inner {
		background-color: #354f5c;
		border: solid 1px #c6cbd0;
		box-shadow: none;
	}
	.multi-range-slider .bar-left {
		background-color: #ffffff;
		border-radius: 6px 0px 0px 6px;
		box-shadow: none;
		border: solid 1px #c6cbd0;
	}
	.multi-range-slider .bar-right {
		background-color: #ffffff;
		border-radius: 0px 6px 6px 0px;
		box-shadow: none;
		border: solid 1px #c6cbd0;
	}
	.multi-range-slider .ruler .ruler-rule {
		border-color: #babec3;
		border-bottom: none;
		padding: 3px 0;
	}
	.multi-range-slider .ruler .ruler-rule:first-child {
		border-color: #454545;
	}
	.multi-range-slider .thumb {
		margin-top: 2px;
	}
	.multi-range-slider .thumb * {
		background-color: #354f5c;
		box-shadow: 0px 0px 5px #a3a0a0;
	}
}
.main-filter .text-caption {
	font-size: 14px !important;
	color: #393d3b;
}
.modal-tile {
	color: #354f5c !important;
}
