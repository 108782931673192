/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    01. Basic Styles
---------------------------------------------------------------------------------------------------------------------------------------------------- */
:root {
	font-size: 16px !important;
	/*   background-color: #fff;
 */
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    02. Border Radius ( 10 : 100 )
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 1 through 10 {
	.radius-#{$i*10} {
		border-radius: #{$i * 10}px !important;
	}
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    03. Opacity ( 10 : 100 )
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 1 through 10 {
	.opacity-#{$i*10} {
		opacity: #{$i/10} !important;
	}
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    04. Margins
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 1 through 10 {
	.mt-#{$i*10} {
		margin-top: #{$i * 10}px !important;
	}
}

@for $i from 1 through 10 {
	.mb-#{$i*10} {
		margin-bottom: #{$i * 10}px !important;
	}
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    05. General Styles
---------------------------------------------------------------------------------------------------------------------------------------------------- */
// Add your general styles here...
.navbar {
	z-index: 99999;
}
.form-control:focus {
	box-shadow: none;
}

select {
	cursor: pointer;
}

.dropdown-menu {
	min-width: auto;
} /*navbar*/

.dropdown-toggle,
.setting-dropdown .btn {
	background: transparent;
	border: none;
	&:hover,
	&:focus {
		border: none;
		background: transparent;
		outline: 0;
	}
}

.btn-clear {
	border: 0;
	background: none;
}

.pointer {
	cursor: pointer !important;
}

//Custom-Col
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
	padding-right: 5px;
	padding-left: 5px;
}

.grid-column-12 {
	grid-column: span 10 !important;
}

.form-group {
	margin-bottom: 0rem !important;
}

.w-30 {
	width: 30%;
}

.disable {
	background-color: gray !important;
}
.false {
	&:hover {
		background: transparent !important;
		color: #656565 !important;
		cursor: not-allowed;
	}
}
.btn {
	font-size: $text-body;
}
.btn-link {
	// color: $blueBlack !important;
	font-weight: 700;
}

.v-divider {
	border: 1px solid #c8c7c7;
}
.no-gutters {
	> [class*="col-"] {
		background: #f4f4f4;
	}
	.control-field__select {
		width: 100%;
		/* 		background: transparent;
 */
		div[class$="-control"] {
			max-height: 18px;
			border: 0px !important;
			min-height: 18px !important;
			box-shadow: 0 0 0 0 transparent !important;
			background: transparent;
			cursor: pointer;
			div[class$="-1hwfws3"] {
				min-height: 18px !important;
				padding: 0 !important;
				position: unset !important;
			}
			.css-1wy0on6 {
				display: unset !important;
			}
			div[class$="-ValueContainer"] {
				max-height: 18px;
				position: unset !important;
				padding: 0 !important;
				.css-1wa3eu0-placeholder {
					margin: 0 !important;
				}
			}
			.css-1okebmr-indicatorSeparator {
				display: none;
			}
			div[class$="-indicatorContainer"] {
				margin: -4px;
				padding: 0 !important;
			}
		}
	}
	// .pr-3,
	// .pl-3 {
	// 	background: #fff url("../../assets/images/svg/down-chevron.svg") no-repeat
	// 		var(--right) 0 center/10px 10px !important;
	// }
}
.isDisabled {
	background: #fff;

	.css-1fhf3k1-control {
		background: #fff !important;
	}
	input[type="text"]:disabled {
		background: #fff;
		opacity: 0.5 !important;
	}

	input::placeholder {
		color: black;
		opacity: 1;
		font-weight: bold;
	}

	input:disabled {
		&::placeholder {
			font-weight: 100 !important;
			color: #9c9c9c !important;
		}
	}

	input:-ms-input-placeholder {
		color: black;
	}

	input::-ms-input-placeholder {
		color: black;
	}
}
#hotelDropDown {
	background: transparent;
	div[class*="-indicatorContainer"] {
		display: none !important;
	}
}
.fa-star {
	color: #ffc649;
}

.free-cancelationn,
.cancelation-conditionn {
	display: none;
}

.react-datepicker-wrapper {
	width: 100% !important;
}
input:focus::-webkit-input-placeholder {
	opacity: 0;
}
.dropdown-item {
	text-align: justify;
}

//fadeIn Tabs
.tab-pane.active {
	animation-name: tabFade;
	animation-duration: 500ms;
	animation-timing-function: linear;
}

.field--danger {
	border: 1px solid #ff5f59 !important;
	input::placeholder {
		color: #ff5f59;
	}
}

html[dir="rtl"] {
	// Handling Icon Plane
	body {
		direction: rtl;
		.product-build__product-collpase {
			.product-arrival {
				.fa-plane {
					transform: rotate(180deg);
					&::after {
						transform: translate(-100%, 0.5px);
					}
				}
			}
		}

		//Handle Icon Cheveron
		.fa-chevron-right,
		.fa-moon {
			-webkit-transform: rotateY(180deg);
			-moz-transform: rotateY(180deg);
			transform: rotateY(180deg);
		}
	}

	/**Product Build Module**/
	.product-content-items {
		.product-arrival {
			.fa-circle,
			.fa-plane {
				transform: rotate(180deg);
			}
		}
	}
	.form-check {
		padding-right: 1.25rem;
		.form-check-input {
			margin-right: -1.25rem;
		}
	}
	/**Product Build Module**/

	/**MarketPlace Module  **/

	// marketSearch
	.mybooking-input-wrapper {
		.form-group {
			.form-control-custom-input {
				padding: 15px 40px 0px 10px;
			}
		}
	}
	.market-search {
		.mybooking-input-wrapper {
			.fa-plane {
				transform: translate(-25%, 0%) rotate(-45deg);
			}
		}
	}
	.market-search {
		.global-input {
			padding: 15px 45px 0px 10px;
		}
	}

	.lSSlideOuter.vertical .lSAction > a {
		right: 50%;
	}
}

.home-section {
	padding: 5rem;
	display: flex;
	text-align: center;
	flex-wrap: wrap;
	button {
		width: 29.333%;
		margin: 1rem;
		padding: 1rem 0.75rem;
		font-size: 20px;
	}
}
.page-item {
	border: 1px solid #808080a8;
	margin: 0 2px;
	border-radius: 5px;
	overflow: auto;
}
.img-lang {
	height: 50px;
	border-radius: 50%;
}
.fullHeight {
	height: 70vh !important;
}

.vh-35 {
	min-height: 35vh;
}
.rgbaBackground {
	background: rgba($color: #fff, $alpha: 0.7) !important;
}

[dir="rtl"] .modal-header {
	.close {
		margin: -1rem -1rem auto -1rem;
	}
}

.react-datepicker__input-container {
	input {
		font-weight: 600;
	}
}

.notification-item {
	top: 70px;
}

.navbar {
	z-index: 999;
}
.branch-collapse {
	.title-style-container {
		// cursor: pointer;
		&.gray {
			.img-circle-wrapper {
				background-color: gray !important;
			}
			.product-content-items {
				h6,
				i {
					color: gray !important;
				}
			}
			.icon-items-section {
				cursor: pointer;
				i {
					color: gray !important;
				}
			}
		}
		&.blue {
			.img-circle-wrapper {
				background-color: rgb(1, 132, 254) !important;
			}
			.product-content-items {
				h6,
				i {
					color: rgb(1, 132, 254) !important;
				}
			}
			.icon-items-section {
				i {
					color: rgb(1, 132, 254) !important;
				}
			}
		}
	}
}

.dropdown-menu {
	z-index: 99999;
}

.btn-light:focus,
.btn-light.focus {
	box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
	box-shadow: none;
}
.DateInput {
	width: 100% !important;
	/* 	max-width: 130px !important;
 */
}
.DateRangePickerInput,
.SingleDatePickerInput {
	height: 18px;
	overflow: hidden;
	border: 0 !important;
	display: flex !important;
	.DateInput_input__focused {
		border-bottom: 0 !important;
	}
	.DateInput_input {
		font-size: $text-caption !important;
		padding: 0 !important;
		line-height: 0 !important;
	}
	.CalendarDay__selected,
	.CalendarDay__selected:active,
	.CalendarDay__selected:hover {
		background: $blue !important;
		border: 1px double $blue !important;
	}
	.CalendarDay__selected_span,
	.CalendarDay__hovered_span,
	.CalendarDay__hovered_span:hover {
		color: white !important;
		background: rgba(1, 123, 254, 0.7) !important;
		border: 1px double rgba(1, 123, 254, 0.7) !important;
	}
	.DateInput_input__disabled {
		font-style: inherit !important;
	}
	.DateRangePickerInput_arrow_svg {
		display: none;
	}
}

.DateRangePicker_picker,
.SingleDatePicker_picker {
	top: 41px !important;
	left: -10px !important;
	z-index: 3 !important;
	[dir="rtl"] & {
		left: auto;
		right: -10px !important;
	}
}

.DateInput_fang {
	top: 31px !important;
	[dir="rtl"] & {
		left: auto !important;
		right: 22px;
	}
}

input {
	cursor: pointer !important;
	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
.roundedButton {
	border-radius: 50%;
	width: 32px;
	height: 32px;
	i:not(.fa-trash) {
		vertical-align: bottom;
	}
	i {
		vertical-align: baseline;
		margin-left: -1px;
		line-height: initial;
	}
}
.discountAdjust {
	height: 48px;
	display: flex;
	align-items: center;
}

.ml-auto {
	[dir="rtl"] & {
		margin-left: initial !important;
		margin-right: auto !important;
	}
}

.css-26l3qy-menu {
	z-index: 9999 !important;
}

//Handle Chechbox RTL
.custom-control {
	[dir="rtl"] & {
		padding-left: initial;
		padding-right: 1.5rem;
	}
}
.custom-control-label {
	&::before {
		[dir="rtl"] & {
			left: auto;
			right: -1.5rem;
		}
	}
	&::after {
		[dir="rtl"] & {
			left: auto;
			right: -1.5rem;
		}
	}
}

.disabled {
	background-color: #eae8e9 !important;
	cursor: no-drop !important;
}

.loader-with-background {
	background-color: #d2dae494 !important;
}

.progress {
	height: 1.5rem;
}
.h-40 {
	height: 40px !important;
}
.font-10 {
	font-size: 10px;
}

.icon-status {
	line-height: 20px;
	/* 	background: red;
 */
	color: #fff;
	border-radius: 50%;

	width: 20px !important;
	height: 20px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	.fas {
		font-size: 9px;
		text-align: center;
		margin: auto;
	}
}

.text-with-select {
	display: flex;
	.input-group {
		z-index: 2;
		border-top-right-radius: unset;
		border-bottom-right-radius: unset;
	}
	.control-field__body {
		flex-basis: 80% !important;
	}
	small {
		width: 98%;
		height: 79%;
		top: 10%;
		left: 3px;
		border-radius: 4px;
		position: absolute;
		z-index: -1;
	}
	& > div:last-child {
		flex-basis: 45% !important;
		// z-index: 1;

		.control-field {
			border-top-left-radius: unset;
			border-bottom-left-radius: unset;
		}
	}
}
.password .control-field__body {
	.row.no-gutters {
		position: relative;
	}
	.control-field__input {
		width: 100%;
	}
	span {
		position: absolute;
		right: 3%;
		top: 30%;
	}
}
.toggle {
	position: absolute;
	z-index: 1;
	right: 20px;
	top: 38px;
	button:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	button:last-child {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	button {
		height: 35px;
		border: 1px solid #e1e4e6;
		background: none;
		color: #b1b1b1;

		&.on {
			background: #007bff;
			color: #fff;
			border: 1px solid #007bff;
		}
	}
}

[dir="rtl"] .toggle {
	right: auto;
	left: 20px;
	button:first-child {
		border-top-left-radius: unset;
		border-bottom-left-radius: unset;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	button:last-child {
		border-top-right-radius: unset;
		border-bottom-right-radius: unset;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
}

.nav-tabs {
	border-bottom: none;
}

.btn-link {
	border: none;
	background: none;
	color: #007bff;
	padding: 0;
	&:hover {
		text-decoration: none !important;
	}
}

.time-picker {
	border: 1px solid;
	background-color: white;
	padding: 10px 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	.from {
		.react-time-picker__wrapper {
			border: none;
			border-inline-end: 1px solid #acacac;
			padding-inline-end: 5px;
		}
	}
	.to {
		.react-time-picker__wrapper {
			padding-inline-start: 5px;
			border: none;
		}
	}
}

.form-check-label {
	cursor: pointer !important;
}

.form-check-inline {
	[dir="rtl"] & {
		margin-left: 0.75rem;
	}
}

.modal .form-check .form-check-input {
	[dir="rtl"] & {
		margin-left: 0.5rem;
	}
}

//fadeIn Tabs
.tab-pane.active {
	animation-name: tabFade;
	animation-duration: 500ms;
	animation-timing-function: linear;
}

// FadeIn Tabs
@keyframes tabFade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

//HeaderDropdown
.dropdown-toggle,
.setting-dropdown .btn {
	background: transparent;
	border: none;
	&:hover,
	&:focus {
		border: none;
		background: transparent;
		outline: 0;
	}
}

// Data Empty
.product-no-data {
	min-height: 25vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #c6cbd0;
	h4 {
		margin-top: 10px;
		font-size: $text-headline;
	}
}
a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
	color: inherit;
}

.multi .no-gutters {
	.control-field__select {
		div[class$="-control"] {
			div[class$="-ValueContainer"] {
				max-height: 20px !important;
			}
		}
	}
}

.icon-time {
	position: absolute !important;
	bottom: 10px !important;
	right: 10px !important;
}
.control-field__prepend {
	position: absolute;
	background: #ddd;
	top: 42%;
	left: 0px;
	z-index: 2;
	width: 60px;
	height: 37px;
	.form-control {
		color: #000000;
		background-color: #ddd;
		border: 1px solid #fff;
		height: 32px;
		padding: 0 !important;
	}
}
.control-field__prepend + .control-field__body {
	.control-field__input {
		border: 1px solid #d1d6dd;
		border-radius: 3px;
		font-size: 0.875rem;
		height: 38px;
		width: 90%;
		color: #324356;
		padding: 9px 67px;
	}
}
.control-field__textarea_input {
	border: 1px solid #d1d6dd;
	border-radius: 3px;
	font-size: 0.875rem;
	height: auto !important;
	width: 90%;
	color: #324356;
	padding: 9px 67px;
}
//commission input %
.modal-body {
	.input-group {
		position: relative;
	}
	.control-field__append {
		position: absolute;
		height: 34px;
		bottom: 13px;
		right: 15px;
		border-inline-start: none !important;

		.form-control {
			background-color: transparent !important;
		}
	}
}

a {
	i[class*="fa-angle"] {
		color: #007bff !important;
	}
}
a {
	i[class*="fa-chevron"] {
		color: #007bff !important;
	}
}
.date-range-input {
	.control-field {
		margin: 0 !important;
	}
	.control-field__body {
		border: 1px solid #d1d6dd !important;
		padding: 10px 13px !important;
		background-color: #fff !important;
	}
}
.date-range-input-right-50 {
	.DayPicker_focusRegion.DayPicker_focusRegion_1 {
		position: absolute;
		right: 50%;
	}
	.DayPicker_weekHeaders {
		position: relative;
		right: 50%;
	}
}
.timePicker-property {
	.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
		width: 19px !important;
	}
	.control-field__body {
		span {
			position: absolute;
			/* right: 76%; */
			top: -2%;
			left: 18%;
			display: contents;
		}
	}
}
