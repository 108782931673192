.calendar-room {
	min-height: 300px;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	overflow: inherit;
	margin-bottom: 2rem;
	.calendar-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem;
		background: #f5f5f5;
		h6 {
			font-weight: 700;
		}
	}
	.calendar-parent {
		display: flex;
		.calendar-title {
			width: 20%;
			li {
				font-size: 12px;
				&:first-child {
					min-height: 40px;
				}
				padding: 0.5rem;
				border: 1px solid #eee;
				min-height: 60px;
			}
			.child-country {
				background: #e7f1ff;
				border: none;
				height: 60px !important;
				padding: 20px;
			}
		}
		.calendar-select {
			height: 60px;
			padding: 0.1rem 0.3rem;
			border: none;
			.control-field {
				margin: 0 !important;
				border: none;
			}
			.control-field__body {
				padding: 0 !important;
			}
		}
		.day-parent {
			display: flex;
			width: 80%;
			overflow-x: auto;
			.calendar-day {
				display: block;
				li {
					width: 100px;
					font-size: 12px;
					position: relative;
					&:first-child {
						min-height: 40px;
						display: block;
						padding-left: 0.3rem;
						padding-right: 0.3rem;
					}
					padding: 2px;
					border: 1px solid #eee;
					min-height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					p {
						line-height: 17px;
						font-size: 12px;
					}
				}
				input {
					width: 100%;
					height: 100%;
					height: 50px;
					border: none;
					text-align: center;
				}
				.currency {
					position: absolute;
					bottom: 5px;
					font-size: 10px;
					color: #a7afb7;
				}

				&.non-bookable {
					.line {
						border: none;
						span {
							height: 20px;
							line-height: 20px;
							background: #ff5050;
							width: 100%;
							position: absolute;
							color: #fff;
							padding: 0 0.5rem;
							font-size: 10px;
						}
					}
					&:last-child {
						.line {
							.span {
								border-radius: 10px;
							}
						}
					}
					&.first {
						.line {
							padding-left: 0.2rem;
							[dir="rtl"] & {
								padding-left: 0;
								padding-right: 0.2rem;
							}
						}
						span {
							border-bottom-left-radius: 7px;
							border-top-left-radius: 7px;
							[dir="rtl"] & {
								border-bottom-left-radius: initial;
								border-top-left-radius: initial;

								border-bottom-right-radius: 7px;
								border-top-right-radius: 7px;
							}
						}
					}
					&.last {
						// .line {
						// 	margin-right: 0.2rem;
						// 	[dir="rtl"] & {
						// 		margin-right: 0;
						// 		margin-left: 0.2rem;
						// 	}
						// }
						span {
							border-bottom-right-radius: 7px;
							border-top-right-radius: 7px;
							[dir="rtl"] & {
								border-bottom-right-radius: initial;
								border-top-right-radius: initial;

								border-bottom-left-radius: 7px;
								border-top-left-radius: 7px;
							}
						}
					}
				}
				&.bookable {
					.line {
						border: none;
						span {
							height: 20px;
							line-height: 20px;
							background: #13dab5;
							width: 100%;
							position: absolute;
							color: #fff;
							padding: 0 0.5rem;
							font-size: 10px;
						}
					}
					&.first {
						.line {
							padding-left: 0.2rem;
							[dir="rtl"] & {
								padding-left: 0;
								padding-right: 0.2rem;
							}
						}
						span {
							border-bottom-left-radius: 7px;
							border-top-left-radius: 7px;
							[dir="rtl"] & {
								border-bottom-left-radius: initial;
								border-top-left-radius: initial;

								border-bottom-right-radius: 7px;
								border-top-right-radius: 7px;
							}
						}
					}
					&.last {
						// .line {
						// 	margin-right: 0.2rem;
						// 	[dir="rtl"] & {
						// 		margin-right: 0;
						// 		margin-left: 0.2rem;
						// 	}
						// }
						span {
							border-bottom-right-radius: 7px;
							border-top-right-radius: 7px;
							[dir="rtl"] & {
								border-bottom-right-radius: initial;
								border-top-right-radius: initial;

								border-bottom-left-radius: 7px;
								border-top-left-radius: 7px;
							}
						}
					}
				}
				.drag-line {
					border-color: #0069d9;
					position: relative;
					> .drag-right,
					.drag-left {
						position: absolute;
						font-size: 15px;
						z-index: 1;
						color: #0069d9;
						cursor: e-resize;
						background: #1877fa;
						color: #fff;
						border-radius: 50%;
						font-size: 9px;
						width: 18px;
						height: 19px;
						text-align: center;
						line-height: 20px;
					}
					> .drag-left {
						display: block;
						left: -9px;
						transform: rotateZ(90deg);
					}
					> .drag-right {
						display: block;
						right: -9px;
						transform: rotateZ(90deg);
					}
				}
				.drag-right {
					display: none;
				}
				.drag-left {
					display: none;
				}
			}
		}
		.collapse {
			padding-top: 0.4rem;
		}
	} //calendar-parent
}
.empty-block {
	border: 0 !important;
	background: #f4f4f4;
}

/*** Bulk Edit ***/
.bulk-edit {
	margin: inherit;
	margin-left: auto;
	[dir="rtl"] & {
		margin-left: initial;
		margin-right: auto;
	}
	.modal-content {
		height: 100vh;
		border-radius: 0;
		border: 0;
		.modal-header {
			display: flex;
			align-items: center;
			.modal-title {
				font-weight: bold;
				font-size: 14px;
			}
		}
	}
	.input-group {
		margin: 0.3rem 0 !important;
	}
	@media (min-width: 576px) {
		max-width: 662px;
	}
} //bulk-edit

.calendar-select {
	overflow: initial;
	height: auto !important;
	.no-gutters {
		.control-field__select div[class$="-control"] {
			max-height: unset !important;
			div[class$="-ValueContainer"] {
				max-height: unset !important;
			}
		}
	}
}
