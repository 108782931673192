#reserv-payment {
	.payment_type_cards > div {
		width: 154px;
		padding: 0.75rem;
	}
	.payment_type_cards {
		justify-content: center;
		.pay-gray {
			background-color: #f2f2f2;
			h5 {
				color: $dark-blue;
			}
		}

		.pay-success {
			background-color: #b1e5c55f;
			h5 {
				color: #13ba52;
			}
		}

		.pay-danger {
			background-color: #f9ccd75f;
			h5 {
				color: #ee2548;
			}
		}

		.pay-gray p,
		.pay-success p,
		.pay-danger p {
			color: $dark-blue;
		}
	}

	.payment_table {
		.table_row_refund td {
			color: #ee2548;
		}

		td.table_col_confirmed {
			color: $green;
		}

		td.table_col_pending {
			color: #b17a00;
		}
		td .text-dark {
			color: $dark-blue !important;
		}
	}
}
