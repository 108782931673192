.manual-suppliers-payment {
	.payment_cards > div {
		width: 200px;
		padding: 15px;
	}
	.payment_cards {
		justify-content: center;
		.pay-gray {
			background-color: #f2f2f2;
			h5 {
				color: #324356;
			}
		}
		.pay-success {
			background-color: #b1e5c55f;
			h5 {
				color: #13ba52;
			}
		}
		.pay-danger {
			background-color: #f9ccd75f;
			h5 {
				color: #ee2548;
			}
		}
		.pay-gray p,
		.pay-success p,
		.pay-danger p {
			color: #324356;
		}
	}
	.text-dark-yellow {
		color: #b17a00;
	}
	.payment-type-row {
		background-color: #d6e8df !important;
		margin-bottom: 15px;
		.payment-type {
			display: flex;
			justify-content: center;
			align-items: center;
			i {
				font-size: 30px;
			}
			span {
				font-size: 21px;
				font-weight: 800;
			}
		}
	}
	.financial-payment-table {
		.custom-table {
			margin-bottom: 0 !important;
		}
		.custom-table th:first-child,
		.custom-table td:last-child {
			border-radius: 0 !important;
		}
		tr,
		td {
			padding: 0 !important;
			margin: 0 !important;
			border-radius: 0 !important;
			border: 0 !important;
		}
		.collapse-btn {
			border: none !important;
			font-size: 10px;
			width: 36px !important;
			//background-color: #313533;
			height: 34px !important;
		}
		.custom-table thead th {
			border-bottom: none !important;
		}
		.collapse {
			.table {
				margin-left: 36px;
				width: 97%;
			}
		}
	}
	.bg-dark-blue {
		background-color: #354f5c !important;
	}
	.bg-light-gray {
		background-color: #d1d6dd !important;
	}
	.tabs-currencies {
		flex: 1 1 auto;
		padding: 0 !important;
		border: 1px solid #e9e9ef !important;
		border-top: 0px !important;
		.nav-tabs {
			background: #f9f9f9;
			box-shadow: 1px 1px 5px #eaeaea;
		}
		.nav-tabs .nav-link {
			background: gainsboro !important;
			padding: 12px 0 !important;
		}
		.nav-tabs .nav-link:after {
			background-color: transparent !important;
		}
		.nav-tabs .nav-link.active {
			background: #505050 !important;
			color: #fff !important;
			font-weight: 600;
		}
		.nav-justified .nav-item,
		.nav-justified > .nav-link {
			flex-basis: unset;
			flex-grow: unset;
			text-align: center;
			width: 11% !important;
		}
	}
}
