#reserv-remarks {
	& .control-field__body {
		padding: 0 !important;
	}
	& .control-field__input {
		height: auto !important;
	}
	.remarks_content {
		background-color: #f7f7f7;
		border-color: #c6cbd0;
	}
	.remarks_content p:first-child {
		color: #354f5c;
		font-size: 14px;
		margin: 0;
	}
	.remarks_content p:last-child {
		color: #3c3c3c;
		font-size: 16px;
		margin: 0;
	}
}
