// Colors
$black: #000000;
$white: #ffffff;
$gray-100: #f4f4f4;
$gray-150: #e8e8e8;
$gray-200: #eae8e9;
$gray-250: #c8c7c7;
$gray-300: #acacac;
$gray-400: #dee1e3;
$dark-gray: #686d71;
$dark-blue: #324356;
$blue: #0184fe;
$red: #ff5f59;
$orange: #f28f08;
$green-100: #00b43740;
$green: #00b437;
$goldColor: gold;
$orangColor: #dc7542;
$blueBlack: #354f5c;
$whiteBlue: #edf2f7;
$b2cPrimary: #d7a04b;
$b2cSecondary: #0f3e5e;
// Font Sizes
$text-title: 24px;
$text-title2: 22px;
$text-title3: 20px;
$text-title4: 25px;
$text-subtitle: 18px;
$text-headline: 16px;
$text-body: 14px;
$text-caption: 14px;
$text-captio2: 10px;
$text-captio3: 11px;

// IcoMoon
$icomoon-font-family: "SBS" !default;
$icomoon-font-path: "../../assets/fonts/icomoon" !default;

$icx-arrow-right: "\e908";
$icx-bell: "\e909";
$icx-financail-payment: "\e90a";
$icx-comment: "\e90b";
$icx-camera-location: "\e90c";
$icx-edit: "\e90d";
$icx-hotel: "\e90e";
$icx-map: "\e90f";
$icx-itinerary: "\e910";
$icx-moon: "\e911";
$icx-plane: "\e912";
$icx-share: "\e913";
$icx-star: "\e914";
$icx-transport: "\e915";
$icx-trash: "\e916";
$icx-eye-slash: "\e907";
$icx-building: "\e900";
$icx-eye: "\e901";
$icx-images: "\e902";
$icx-shield: "\e905";
$icx-user: "\e906";
$font14: 14px;

$closedNav: 60px;
$opendNav: 250px;
$opendNavMax: 25%;
$gray-500: #99a4b1;
$orange-500: #ba5f20;
