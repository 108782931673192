.amount {
	width: 250px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 10px;
	box-shadow: 0px 3px 6px #00000029;
	color: #324356;
	.type {
		font-weight: bold;
		margin-bottom: 0px;
		color: #324356;
	}
}
.total {
	background-color: #f2f2f2;
}
.paid {
	background-color: #00b54531;
}
.Remaining {
	background-color: #f1dfe6;
}
.border-box {
	border: 1px solid #d1d6dd;
	border-bottom: none;
	height: 140px;
	width: 100%;
}
.financialTable {
	thead {
		vertical-align: middle !important;
	}

	tr th {
		border: 1px solid #d1d6dd;
		//text-align: center;
		padding-inline: 5px !important;
		font-size: 14px;
		border-collapse: collapse;
		padding: 0px;
		font-weight: 600;
		label {
			font-weight: 900;
		}
	}

	tbody tr td {
		border: 1px solid #d1d6dd;
		//text-align: center;
		padding-inline: 5px !important;
		font-size: 14px;
		padding: 1px;
		border-collapse: collapse;
		color: #313533;
		box-shadow: none;
		.operations {
			i {
				color: #313533;
				font-size: 14px;
			}
		}
		i {
			color: #7c929a;
		}
	}
	tbody > .table-active {
		background-color: blue !important;
	}
}
.financial-pay {
	.payment-type-row {
		background-color: #eee;
		.payment-type {
			font-size: 25px;
			color: #000;
		}
	}
}
.tarnsaction_model {
	display: flex;
	column-gap: 20px;

	.company-field {
		background-color: #eaeaea;
		border: 1px solid #d1d6dd;
		padding: 10px;
		width: 50%;
		p {
			margin-bottom: 0px;
		}
	}
}
.attachment {
	border: 2px dotted #d1d6dd;
	padding: 20px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	.add-photo {
		background-color: #00aaeb;
		box-shadow: 0px 3px 6px #00aaeb;
		padding: 10px;
		color: #fff;
		width: 30%;
		margin: auto;
		position: relative;
	}
}
.btn-x {
	background-color: #d1d6dd;
	border-radius: 10px;
	color: #fff;
}

.bg-sea {
	background-color: #c2ebf5 !important;
}
.bg-army {
	background-color: #dbeccd !important;
}
.bg-pinky {
	background-color: #e7d2e7 !important;
}
.bg-border {
	background-color: #dfdfe1 !important;
}

.crm-filter {
	display: flex;
	align-items: center;
	padding: 5px 0px;
	.control-field__body {
		padding: 0px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
	}
	.control-field {
		margin: 0px !important;
	}
	.control-field__input {
		border: 0px;
		background-color: #fbfaff !important;
	}
	.no-gutters {
		width: 100% !important;
	}
	.control-field__feedback {
		display: none !important;
	}
	.btn-crm {
		border: 1px solid #d1d6dd !important;
		background-color: #fff;
		padding: 4px;
		color: #b8b8ba;
	}
}
.header-filter-crm {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ebebeb;
	.left-btns {
		.btn-header {
			border-right: 1px solid #fff !important;
			border-radius: 0 !important;
		}
	}
	.right-btns {
		.btn-header {
			border-left: 1px solid #fff !important;
			border-radius: 0 !important;
		}
	}
}
.status-bar {
	display: flex;
	align-items: center;
	border-radius: 10px;
	overflow: hidden;
	margin-top: 10px;
	p {
		margin-bottom: 0px;
	}
	.status-name {
		width: 120px;
		padding: 15px;
		margin-inline-end: 15px;
		color: #fff;
		font-weight: bold;
		text-align: center;
	}
}
.status {
	background-color: #c2e5f5;
	.status-name {
		background-color: #75aed1;
	}
}
.deprture {
	background-color: #dbeccd;
	.status-name {
		background-color: #9ec380;
	}
}
.closing {
	background-color: #f2e3c3;
	.status-name {
		background-color: #c4aa62;
	}
}

.requestTaps {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	top: 1px;

	.tap {
		background-color: #d1d6dd;
		border: 1px solid #e4e6e9;
		padding: 4px 10px;
		color: #7c929a;
	}

	.active {
		background-color: #354f5c;
		color: #fff;
	}
}

.summarybox {
	background-color: #fff;
	border: 1px solid #d1d6dd;
	border-radius: 0 5px 5px 5px;
	padding: 20px;
}

.summaryhead {
	font-weight: bold;
	color: #000;
	font-size: 20px;
	margin-bottom: 20px;
}

.info-row {
	display: flex;
	flex-direction: row;
}

.label {
	font-weight: bold;
	color: #595e62;
	text-align: end;
	width: 35%;
}

.info {
	color: #000000;
	padding-inline-start: 10px;
	width: 60%;
}

.add-pass {
	background-color: #cb9a51;
	box-shadow: 0px 3px 6px #cb9a515f;
	border-radius: 5px;
	color: #fff;
}

.totalBox {
	background-color: #e5e5e5;
	box-shadow: 0px 3px 6px #adadad80;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
}

.total {
	font-weight: bold;
}

.darknum {
	color: #112e53;
}

.greennum {
	color: #027b30;
}

.yellownum {
	color: #b17a1f;
}

.side-col {
	background-color: white;
	border: 1px solid #d1d6dd;
	border-radius: 5px;
	margin-inline-end: 20px;

	.tap {
		width: 46%;
		text-align: center;
		padding: 5px 10px;
		border-radius: 5px 0px 0px 5px;
		font-weight: bold;
	}

	.tap:last-child {
		border-radius: 0px 5px 5px 0px;
		[dir="rtl"] & {
			border-radius: 5px 0px 0px 5px;
		}
	}
	.tap:first-child {
		[dir="rtl"] & {
			border-radius: 0px 5px 5px 0px;
		}
	}

	.requestTaps {
		margin-bottom: 20px;
		padding: 20px;
		justify-content: center;
	}
	.Inbound {
		background-color: #709a47;
		color: #fff;
		padding: 5px 10px;
	}

	.Outbound {
		background-color: #f04129;
		color: #fff;
		padding: 5px 10px;
	}

	textarea {
		border: 1px solid #d1d6dd;
		border-radius: 3px;
	}

	textarea::placeholder {
		color: #d1d6dd;
	}
}
.btn-color {
	background-color: #b17a1f;
}

.history {
	border-top: 1px solid #d1d6dd;

	h1 {
		font-weight: bold;
		color: #000;
		font-size: 18px;
	}
}
.head {
	color: #7c929a;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	font-weight: 500;
	p {
		margin-bottom: 0px;
	}
}

.descrip {
	color: #000;
	font-size: 12px;
}
.pax-filter {
	.control-field__body {
		padding: 0px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
	}
	.control-field {
		margin: 10px 0px !important;
	}

	.no-gutters {
		width: 350px !important;
	}
	.control-field__feedback {
		display: none !important;
	}
}

.Priority-model {
	label {
		margin-bottom: 0px;
	}

	.High,
	#High {
		accent-color: #12a67a;
		color: #ee2548;
	}
	.Medium,
	#Medium {
		accent-color: #b17a00;
		color: #b17a00;
	}
	.Low,
	#Low {
		accent-color: #313533;
		color: #313533;
	}
}
.btn-save-model {
	background-color: #00b680 !important;
	color: #fff !important;
	width: 70%;
	margin: auto;
}

.warp-field {
	background-color: #d1d6dd;
	display: flex;
	align-items: center;
	border-radius: 5px;
	position: relative;
	.control-field {
		margin: 0px !important;
	}
	.control-field__label {
		display: none !important;
	}
	.control-field__feedback {
		display: none !important;
	}
	.control-field__body {
		padding: 3px !important ;
	}
	.control-field__input {
		height: 32px !important;
	}
	.no-gutters {
		padding: 0px !important;
	}
	p {
		margin-bottom: 0px !important;
		padding: 5px !important;
		color: #9aa0a8 !important;
	}
	.control-field__append {
		bottom: 6px !important;
		right: 1px !important;
		span {
			font-size: 10px !important;
			color: #7c929a !important;
			padding-right: 0px !important;
		}
	}
}
.filter-warp-field {
	background-color: #d1d6dd;
	display: flex;
	align-items: center;
	border-radius: 5px;
	position: relative;
	.control-field {
		margin: 0px !important;
	}
	.control-field__label {
		display: none !important;
	}
	.control-field__feedback {
		display: none !important;
	}
	.control-field__body {
		padding: 3px !important ;
	}
	.control-field__input {
		height: 32px !important;
	}
	.no-gutters {
		padding: 0px !important;
	}
	p {
		margin-bottom: 0px !important;
		padding: 5px !important;
		color: #9aa0a8 !important;
	}
	.control-field__append {
		position: absolute;
		bottom: 6px !important;
		right: 1px !important;
		background-color: none !important;
		border-inline-start: 0px !important;
		span {
			font-size: 10px !important;
			color: #7c929a !important;
			padding: 0px !important;
		}
	}
}

.wrap-label {
	margin-bottom: 0px !important;
}

.Selected-field {
	.control-field {
		margin: 0px !important;
	}
	.control-field__body {
		padding: 0px;
		margin-top: 7px;
	}
	.control-field__select {
		height: 36px !important;
	}
}
.re-badge {
	background-color: #fde351;
	border: 1px solid #d1d6dd !important;
	color: #354f5c;
	border-radius: 5px;
	font-size: 12px;
	padding: 2px 5px;
}
.re-badge.selected {
	background-color: #354f5c;
	color: #fff;
}
.redirect-badge {
	background-color: #112e53;
	color: #ffffff;
	border-radius: 5px;
	i {
		color: #ffff !important;
	}
}
.Package-Details-items {
	background-color: #fff;
	border: 1px solid #d1d6dd;
	border-radius: 0px 5px 5px 5px;
	margin-top: 0.5rem;
	padding: 0.5rem;

	.package-item-container {
		position: relative;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		padding: 0 0 0 45px;
		// overflow: hidden;
		&::before {
			content: "";
			position: absolute;
			width: 0;
			height: 100%;
			top: 40px;
			left: 20px;
			border: 1px dashed #d1d6dd;
		}
		&:last-child {
			&::before {
				border: none;
				display: none;
			}
		}
	}
	.package-item {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		min-height: 90px;
		background-color: #ebebeb;
		border: 1px solid #dfdfdf;
		border-radius: 10px;

		.path-details {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 25%;
			overflow: hidden;
			.path-start-point {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: rgb(192, 188, 188);
			}
			.path-stop {
				background: #28a745;
				padding: 1px 8px;
				border-radius: 5px;
				color: #fff;
			}
			.path-stop,
			img {
				position: relative;
				z-index: 2;
			}
			&::before {
				content: "";
				position: absolute;
				background-color: rgb(192, 188, 188);
				width: 100%;
				height: 2px;
				z-index: 0;
			}
		}
		.icon-item {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			top: 50%;
			left: -44px;
			transform: translateY(-50%);
			background-color: #f2f2f2;
			border-radius: 50%;
		}

		.date-name {
			font-weight: light;
			font-size: small;
		}
		.box-nights {
			display: flex;
			align-items: center;
			margin-inline-end: 20%;
			padding: 0 5px;
			border: 1px solid #dfdfdf;
			border-bottom: 0px;
			border-top: 0px;
			color: #979797;
		}
	}
}
.airprot-name {
	color: #40647d;
	margin-bottom: 0px;
}

.transit-stop {
	position: relative;
	width: 100%;
	background-color: #fff;
}
.air-step {
	position: relative;
	padding: 6px 12px;
	background-color: #f4f9ff;
	// overflow: hidden;
}
.air-step.with-transit::before,
.transit-stop::before {
	content: "";
	position: absolute;
	width: 9px;
	height: 9px;
	top: 50%;
	left: -20px;
	border-radius: 50%;
	border: 1px solid #b1b1b1;
	background-color: #fff;
	transform: translateY(-50%);
	z-index: 1;
}
.air-step.with-transit::after {
	content: "";
	position: absolute;
	width: 0;
	height: 60%;
	border: 1px dashed #b1b1b1;
	top: 50%;
	left: -17px;
	z-index: 0;
}
.air-step:last-child::after {
	top: initial;
	bottom: 50%;
}
.transit-stop::before {
	width: 12px;
	height: 12px;
	left: -22px;
	border: 1px solid #0372bc;
	z-index: 1;
}

.text-light-yellow {
	color: #ba8a2d !important;
}
.bg-meduim-papriority {
	background-color: #fffae7 !important;
}
.bg-high-papriority {
	background-color: #fdedeb !important;
}
.bg-low-papriority {
	background-color: #d1d6dd98 !important;
}
