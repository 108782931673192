// ---------- Start Atraction List ----------
.attractions {
	.attraction-head {
		display: flex;
		justify-content: space-between;
		h5 {
			color: #050001;
			font-weight: 700;
			font-size: 18px;
		}
		div {
			display: flex;
			gap: 15px;

			button {
				padding: 9px 32px;
				border-radius: 8px;
				font-size: 15px;
				font-weight: 700;
			}
			.commission-btn {
				border: 1px solid rgba(1, 157, 76, 0.16);
				background: rgba(1, 157, 76, 0.1);
				color: #019d4c;
			}
		}
	}
	.attraction-filter {
		.control-field__label {
			color: var(--707170, #707170);
			font-size: 14px;
			font-weight: 400;
		}
		.control-field__input {
			padding: 9px 45px !important;
		}
		.date-input {
			.control-field__body {
				padding: 9px;
			}
		}
		.clear-filter {
			height: 38px;
			padding: 12px 16px;
			border-radius: 6px;
			border: 1px solid var(--707170, #707170);
			background: var(--f-7-f-6-f-6, #f7f6f6);
		}
	}
	.attraction-table {
		border: 1px solid #dbdade;
		border-radius: 4px;
		table {
			margin-bottom: 0px !important;
			thead {
				border-bottom: 1px solid #dbdade;
				background: var(--f-7-f-6-f-6, #f7f6f6);
				th {
					color: #707170;
					font-size: 14px;
					font-weight: 700;
					letter-spacing: 1px;
					text-transform: uppercase;
					div {
						color: #707170;
						font-size: 14px;
						font-weight: 600;
						letter-spacing: 1px;
						text-transform: uppercase;
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid #dbdade;
				}
				tr:last-of-type {
					border: 1px solid transparent;
				}
				td {
					color: #050001;
					font-size: 12px;
					font-weight: 600;
					padding: 10px 8px;
				}
				.actions {
					display: flex;
					justify-content: space-between;
					svg {
						cursor: pointer;
					}
				}
			}
		}
	}
	.more-popup {
		// width: max-content;
		background-color: #fff;
		border-radius: 12px;
		position: absolute;
		left: -75%;
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);
		width: 175px;
		border-radius: 8px;
		padding-block: 8px;
		p {
			display: flex;
			align-items: center;
			gap: 5px;
			padding: 4px 16px;
			color: #050001;
			font-size: 16px;
			font-weight: 400;
			border-bottom: 1px solid #dddcdc;
			path {
				stroke: #050001 !important ;
			}
			&:last-of-type {
				border-bottom: none;
			}
			&:hover {
				color: #1e85ff;
				background-color: rgba(30, 133, 255, 0.08);
				path {
					stroke: #1e85ff !important ;
				}
			}
		}
	}
}
// ---------- End Atraction List ----------

// ---------- Start View Atraction  ----------
.view-attraction {
	padding: 20px;
	border-radius: 12px;
	border: 1px solid #dddcdc;
	background: #fff;
	.attractions-info-container {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px dashed #dbdade;
		.attraction-info {
			width: 25%;
			margin-bottom: 20px;
			p {
				color: #707170;
				font-size: 14px;
				font-weight: 600;
				text-transform: uppercase;
				margin-bottom: 5px;
			}
			span {
				color: #050001;
				font-size: 16px;
				font-weight: 500;
			}
		}
	}
	.details-box {
		margin-block: 15px;
		h6 {
			color: #050001;
			font-size: 20px;
			font-weight: 400;
			text-transform: uppercase;
			margin-bottom: 15px;
		}
		p {
			color: #707170;

			font-size: 16px;
			font-weight: 500;
		}
	}
	.photos {
		margin-block: 15px;
		position: relative;

		h6 {
			color: #050001;
			font-size: 20px;
			font-weight: 400;
			text-transform: uppercase;
			margin-bottom: 15px;
		}
		.images {
			display: flex;
			gap: 15px;
			img {
				width: 165px;
				height: 130px;
			}
		}

		.react-multi-carousel-item {
			width: 165px !important;
			height: 130px !important;
		}
		.react-multi-carousel-track {
			gap: 15px;
		}

		.carousel-button-group-new {
			position: absolute;
			width: 100%;
			top: 43%;
			.react-multiple-carousel__arrow {
				background: #f7f6f6;
			}
			.react-multiple-carousel__arrow--right {
				right: 0 !important;
			}
			.react-multiple-carousel__arrow--left {
				left: 0 !important;
			}
			.react-multiple-carousel__arrow:before {
				color: #222;
			}
			.react-multiple-carousel__arrow:hover {
				background: #f7f6f6;
			}
			.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right.disable {
				display: none;
			}
			.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left.disable {
				display: none;
			}
		}

		.react-multi-carousel-list.new-market-packages-countries {
			width: 90%;
			margin: auto;
		}
	}
	.included-services {
		margin-block: 15px;
		h6 {
			color: #050001;
			font-size: 20px;
			font-weight: 400;
			text-transform: uppercase;
			margin-bottom: 15px;
		}
		ul {
			padding-left: 16px;
			list-style: disc;
			color: #707170;
			font-size: 16px;
			font-weight: 700;
		}
	}
	.cancleation-fees {
		display: flex;
		align-items: flex-end;
		gap: 40px;
		div {
			display: flex;
			flex-direction: column;
			h6 {
				color: #050001;
				font-size: 14px;
				font-weight: 500;
			}
			span {
				color: #707170;
				font-size: 14px;
				font-weight: 500;
			}
		}
		p {
			color: #ea5455;
			font-size: 16px;
			font-weight: 600;
		}
	}
	.action-buttons {
		display: flex;
		justify-content: end;
		gap: 15px;
		margin-top: 30px;
		button {
			border-radius: 8px;
			font-size: 15px;
			font-weight: 500;
			box-shadow: none !important;
		}
		.btn-secondary {
			background: rgba(112, 113, 112, 0.1);
			color: #707170;
		}
	}
}
.add-edit-attraction {
	padding: 20px;
	border-radius: 12px;
	border: 1px solid #dddcdc;
	background: #fff;
	.control-field__label,
	.time-label {
		color: #707170;
		font-size: 14px;
		font-weight: 400;
	}
	.included-services {
		p {
			color: #707170;
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 0;
		}
		.control-field__body {
			padding-block: 0;
		}
		button {
			color: #6a4d1b;
			font-size: 15px;
			font-weight: 500;
			box-shadow: none;
		}
	}
	.action-buttons {
		display: flex;
		justify-content: end;
		gap: 15px;
		margin-top: 30px;
		button {
			border-radius: 8px;
			font-size: 15px;
			font-weight: 500;
			box-shadow: none !important;
		}
	}
	.images-gallery {
		display: flex;
		gap: 2px;
		flex-wrap: wrap;
		.image-gallery {
			position: relative;
			.gallery-remove {
				display: none;
			}
			&:hover {
				// border: 1px solid #050001;
				.gallery-remove {
					position: absolute;
					top: 0%;
					width: 100%;
					height: 100%;
					background-color: #0500013f;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
	.time-picker .from .react-time-picker__wrapper {
		border-inline-end: none !important;
	}
}
.share-attraction-modal {
	.title {
		p {
			color: #050001 !important;
			font-size: 18px;
			font-weight: 600;
		}
	}
	.control-field__label,
	.payment_type span {
		color: #707170 !important;
		font-size: 14px !important;
		font-weight: 400 !important;
	}
	.payment_type {
		label {
			color: #050001;
			font-size: 14px;
			font-weight: 400;
		}
		input {
			height: 18px;
			width: 20px;
			vertical-align: middle;
		}
	}
}
.configure-calender-attraction-modal {
	.title {
		p {
			color: #050001 !important;
			font-size: 18px;
			font-weight: 600;
		}
	}
	.control-field__label {
		color: #707170 !important;
		font-size: 14px !important;
		font-weight: 400 !important;
	}
	.btn-add {
		box-shadow: none;
		color: #6a4d1b;
		font-size: 14px;
		font-weight: 600;
		display: flex;
		gap: 12px;
		align-items: center;
		i {
			color: #6a4d1b;
		}
	}
	.time-picker .from .react-time-picker__wrapper {
		border-inline-end: none;
	}
}
.attraction_calender {
	.calender_head {
		padding: 5px 20px;
		border-radius: 12px;
		border: 1px solid #dddcdc;
		background: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		button {
			border-radius: 8px;
			background: #019d4c !important;
			height: 40px;
			padding: 0px 32px;
		}
	}
	.calendar-room {
		margin-top: 25px;
		padding: 5px 20px;
		border-radius: 12px;
		border: 1px solid #dddcdc;
		background: #fff;
	}
	.day-parent .calendar-day li p {
		font-size: 13px !important;
		font-weight: 600;
		color: #313533;
	}
	.line {
		display: flex !important;
		align-items: flex-start !important;
		justify-content: center !important;
		button {
			padding: 0;
			font-size: 14px;
			font-weight: 700;
		}
	}
	.calendar-day li {
		width: 130px !important;
	}
	.calendar-title li {
		border: none !important;
		color: #050001;
		font-size: 14px !important;
		font-weight: 600;
		display: flex;
		align-items: center;
	}
}
.time-confige {
	.control-field {
		width: 90%;
	}
	.control-field__feedback {
		display: none !important;
	}
}

// ---------- End View Atraction  ----------

// Global Classes In Attraction Component
.status-active {
	border-radius: 4px;
	background: rgba(1, 157, 76, 0.16);
	color: #019d4c;
	padding: 5px 10px;
	border-radius: 4px;
}
.status-inactive {
	border-radius: 4px;
	background: rgba(255, 49, 49, 0.16);
	color: #ff3131;
	border-radius: 4px;
	padding: 5px 10px;
}
p {
	margin-bottom: 0 !important;
}
.danger-border {
	border: 1px solid #fd625e !important;
}
%flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.field-has-error {
	border: 1px solid #ff5f59 !important;
}
.has-error {
	color: #ff5f59 !important;
}
.custom-time-field-container {
	position: relative;
	.custom-time-field {
		width: 100%;
		padding: 8px 16px;
		border-radius: 3px;
		border: 1px solid #dddcdc;
		background-color: white;
		display: flex;
		justify-content: space-between;
	}
	.time-field-row {
		margin-top: 8px;
		width: 100%;
		border-radius: 6px;
		box-shadow: 0px 4px 16px 0px #a5a3ae73;
		background-color: white;
		padding: 8px 20%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1;
	}
	.time-details {
		@extend %flex-center;
		border: 1px solid #f7f6f6;
		padding: 4px 36px 4px 36px;
		gap: 8px;
		input {
			width: 22px;
			border: none;
		}
	}
}
