.visa-configuration {
	.head-visa {
		background-color: #c8c8c8;
		color: #354f5c;
		font-size: 18px;
		font-weight: 600;
		padding: 8px 12px;
	}
	.visa-select {
		.backOffice-wrapper .control-field {
			border-radius: 0;
			padding: 0px !important;
			margin: 0 !important;
		}
		.control-field__body {
			flex: 1 1 auto;
			border-radius: 0.3125rem;
			padding: 4px 12px !important;
			/* 	background: #2c302e !important; */
			border-radius: 0px !important;
		}
		.control-field {
			margin: 0 !important;
		}
		.control-field__feedback {
			margin-top: 0 !important;
		}
	}
	.country-visa {
		line-height: 2.8;
		color: #000;
		font-weight: bold;
		font-size: 17px;
		margin: 0;
		font-family: "Cairo";
	}
	.header-add-visa {
		/* box-shadow: 1px 1px 10px #2c302e; */
		border-bottom: 1px solid #eee;
	}
	.copyIcon-configRow {
		color: #03a225;
	}
	.visa-row {
		padding: 8px 15px;
		background-color: #fff;
		.visa-row-content {
			//	gap: 12px;
			.btn-visa {
				display: flex;
				justify-content: end;
				// padding: 10px 0 !important;
				//flex-direction: column-reverse;

				button {
					border-radius: 3px !important;
					background-color: #007bff !important;
					box-shadow: #007bff 0pc 3px 6px;
					margin: auto;
					margin-top: 0px !important;
					padding: 15px 10px !important;
					color: #fff !important;
				}
				button:hover {
					background-color: #007bffd2 !important;
				}
				span.btn-link {
					border: none;
					background: none;
					color: #007bff;
					padding: 0;
					color: #ff5f59 !important;
					cursor: pointer;
					text-decoration: underline;
					font-size: 14px;
				}
			}
		}
		.btn-add-input {
			text-decoration: underline;
			color: #007bff !important;
			padding: 0px;
			font-size: 13px;
			text-align: end;
			width: 19% !important;
		}
		.config-row {
			.control-field__body {
				padding: 0.3125rem 0px !important;
			}
			.DateRangePickerInput .DateInput_input,
			.SingleDatePickerInput .DateInput_input {
				padding: 0 6px !important;
			}
			.copyIcon-configRow {
				color: #03a225;
			}
		}
		.dataPicker-configRow {
			.control-field {
				padding: 5px 0px;
				.SingleDatePicker {
					padding: 4px 0 !important;
				}
			}
		}
		.txt-gray {
			color: #8b8b8b !important;
		}

		.visa-transTypeLabel {
			.control-field__label {
				font-size: 15px !important;
			}
		}
	}
	.visa-include-ground-checkbox:checked {
		background-color: #b1956c !important;
		border-color: #b1956c !important;
	}
	.ground-config-row {
		.control-field__body {
			padding: 0.3125rem 0px !important;
		}
		.DateRangePickerInput .DateInput_input,
		.SingleDatePickerInput .DateInput_input {
			padding: 0 6px !important;
		}
		.btn-add-ground-input {
			text-decoration: underline;
			color: #007bff !important;
			padding: 0px;
			font-size: 13px;
			text-align: end;
			width: 5% !important;
		}
	}
	.no-visa {
		background-color: #f5f5f5;
		color: #a7afb7;
		height: 250px;
		div {
			margin: auto;
			width: 50%;
			text-align: center;
		}
	}
	.arrow-collapse {
		color: #a7afb7;
		font-size: 18px;
		cursor: pointer;
	}

	.save-country {
		position: absolute;
		right: 25px;
	}
}
.w-15 {
	width: 15% !important;
}
.dis-hidden {
	// display: none !important;
	visibility: hidden !important;
}
.boxCancellation {
	text-transform: capitalize;
	padding: 8px;
	height: 62.54px;
	background: #ebebeb;
	text-align: center;
	border-radius: 6px;
	p {
		text-transform: capitalize;
	}
}
.bg-Ground {
	text-transform: capitalize;
	background-color: #e7f5ea;
	padding: 20px 10px;
	p {
		text-transform: capitalize;
		color: #28a745;
	}
}

.bg-dangerr {
	background-color: #f3d6d7;
	padding: 20px 10px;
	p {
		text-transform: capitalize;
		color: #ff6868;
	}
}
.payment-box {
	background-color: #f7f7f7;
	border-radius: 10px;
}
.must-box {
	background-color: #cfead5;
	color: #28a745;
	border-radius: 10px;
}
.box-radio {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border: 1px solid #ebebeb;
	padding: 5px 1px;
	width: 49%;
	border-radius: 5px;
	img,
	p {
		margin: auto;
	}
	p {
		font-size: 14px;
		color: #3b4b5d;
		font-weight: 800;
		margin-top: 8px;
		margin-bottom: 15px;
	}
	input[type="radio"] {
		margin-top: 0px !important;
		font-size: 16px !important;
		width: 1.25em;
		height: 1em;
		accent-color: #b58236 !important;
		//here
		appearance: none;
		border: 2px solid #ddd;
		border-radius: 50%;
		padding: 5px !important;
		width: 15px !important;
		height: 15px !important;
	}
	input[type="radio"]:checked {
		accent-color: #b58236 !important;
		padding: 2px !important;
		background-color: #fff !important;
		border: 5px solid #b58236 !important;
	}
}
.border-active {
	background-color: #f7f1e9;
	border: 1px solid #cb9a51;
	accent-color: #b58236 !important;
}
.bg-nxt {
	background-color: #cb9a51;
	color: #ffffff;
	font-size: 16px;
}

.bg-nxt:hover {
	background-color: #bf8d42;
	color: #ffffff;
}
.btn-pay-disabled:disabled {
	background: #cb9a51 !important;
	color: #fff !important;
}
.inp-confirmation {
	margin: 0;
	.control-field__body {
		padding: 2px 0 !important;
	}
	.control-field {
		border: none !important;
	}
	.input-group,
	.control-field__input {
		background-color: #fff;
		color: #2d2d2d;
	}
	.input-group {
		padding: 0px !important;
		margin: 0px !important;
		height: 37px !important;
		margin-bottom: 11px;
	}
}
.voucher {
	p {
		margin: 0;
	}
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 2px;
	}
	.container-wrapper {
		border: 4px solid #0c3b5c;
	}

	.voucher-header {
		gap: 85px;

		.header-img {
			border: 1px solid #ebebeb;
			width: 310px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.header-name {
			h4 {
				color: #0c3b5c;
				font-size: 18px;
				font-weight: 700;
				margin: 7px 0;
			}
		}
		.header-data {
			width: 60%;
			div {
				margin: 3px 0;
			}
			h5 {
				color: #2d2d2d;
				font-size: 18px;
			}
			p {
				color: #2d2d2d;
				font-weight: 800;
				font-size: 14px;
			}
		}
	}
	hr {
		border-top: 1px solid #0c3b5c !important;
	}
	.services {
		// margin: 8px 0;
		h3 {
			color: #0c3b5c;
			font-size: 18px;
			font-weight: 700;
		}
		.service-data {
			div {
				margin: 3px 0;
			}
			h5 {
				color: #2d2d2d;
				font-size: 20px;
			}
			p {
				color: #2d2d2d;
				font-weight: 800;
				font-size: 17px;
			}
		}
	}
	.passenger {
		// margin: 8px 0;
		h3 {
			color: #0c3b5c;
			font-size: 18px;
			font-weight: 700;
		}
		.passenger-data {
			div {
				margin: 3px 0;
			}
			h5 {
				color: #2d2d2d;
				font-size: 20px;
			}
			p {
				color: #2d2d2d;
				font-weight: 800;
				font-size: 18px;
			}
			.passenger-info {
				background: #f7f7f7;
				border: 4px solid #f4f4f4;
				padding: 25px;
				h6 {
					color: #2d2d2d;
				}
				p {
					color: #0c3b5c;
				}
			}
		}
	}
}
.letter-logo {
	width: 40px;
	height: 40px;
	font-size: 16px;
	font-weight: 600;
	color: white;
	background-color: #cb9a51;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: capitalize;
}
.booking-autoComplete {
	display: grid;
	.input-group {
		margin: 0px !important;
		padding: 0px !important;
	}
	.fix_place_arrow {
		display: none !important;
	}
}
.w-10 {
	width: 10%;
}

.listAuto {
	position: absolute;
	background: #fff;
	z-index: 9999;
	border: 1px solid #8080806e;
	border-radius: 9px;
	overflow: hidden;
	overflow-y: auto;
	max-height: 350px;
	/* top: 79px; */
	width: 100%;
	li {
		width: 100%;
		padding: 12px 7px;
		display: flex;
		border-bottom: 1px solid rgba(128, 128, 128, 0.414);
		img {
			margin: 0 4px;
		}
		&:hover {
			background-color: #0c3b5c98 !important	;
			color: #fff;
			cursor: pointer;
		}
	}
}

.fix_place_arrow {
	display: none !important;
}

.w-10 {
	width: 10%;
}
.MuiInputBase-root.MuiOutlinedInput-root {
	height: 35px !important;
}

.add-country-field-config {
	.control-field {
		margin: 0px auto !important;
		.control-field__body {
			padding: 0px !important;
		}
	}
}

@media print {
	html,
	.bg-gray-100 {
		background-color: #fff !important;
	}
	.voucher {
		//height: 100vh !important;
	}
	.voucher-header {
		gap: 20px !important;
		.header-img {
			border: 1px solid #ebebeb;
			width: 210px !important;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.header-name {
			h4 {
				color: #0c3b5c;
				font-size: 16px !important;
				font-weight: 700;
				margin: 7px 0;
			}
		}
		.header-data {
			width: 60%;
			div {
				margin: 3px 0;
			}
			h5 {
				color: #2d2d2d;
				font-size: 16px !important;
			}
			p {
				color: #2d2d2d;
				font-weight: 800;
				font-size: 14px !important;
			}
		}
	}

	.services {
		// margin: 8px 0;
		h3 {
			color: #0c3b5c;
			font-size: 14px !important;
			font-weight: 500 !important;
		}
		.service-data {
			div {
				margin: 3px 0;
			}
			h5 {
				color: #2d2d2d;
				font-size: 15px !important;
			}
			p {
				color: #2d2d2d;
				font-weight: 500 !important;
				font-size: 15px !important;
			}
		}
	}
	.passenger {
		// margin: 8px 0;
		h3 {
			color: #0c3b5c;
			font-size: 14px !important;
			font-weight: 500 !important;
		}
		.passenger-data {
			div {
				margin: 3px 0;
			}
			h5 {
				color: #2d2d2d;
				font-size: 16px !important;
			}
			p {
				color: #2d2d2d;
				font-weight: 500 !important;
				font-size: 15px !important;
			}
			.passenger-info {
				background: #f7f7f7;
				border: 4px solid #f4f4f4;
				padding: 10px !important;
				h6 {
					color: #2d2d2d;
				}
				p {
					color: #0c3b5c;
				}
			}
		}
	}

	.container-wrapper {
		//height: 97vh !important;
	}
	.voucher h3 {
		background-color: #fff;
	}
	.sidebar.office-navbar {
		display: none !important;
	}
}
.danger {
	border: 1px solid red !important;
}
.popup-ads {
	.items-popupAds-details {
		.file-btn,
		.delete-file-buton {
			background-color: transparent;
			position: relative;
			border-radius: 5px;
			color: white;
			font-weight: 500;
			border: none;
			width: 10%;
			padding: 3px;
			font-size: 14px;
			box-shadow: none !important;
			cursor: pointer;
		}
	}
	.ads-date-field {
		.control-field {
			padding: 0 10px;
		}
	}
}

.file-upload-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 12px;
}
.file-upload-container span {
	font-size: 14px;
}

// file upload
.file-upload-control {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px 12px;
	background-color: #d29d4d;
	border: 1px solid #d1d6dd;
	border-radius: 3px;
	color: #fff;
	cursor: pointer;
	overflow: hidden;
}
.file-upload-control label {
	cursor: inherit;
}
.file-upload-input {
	position: absolute;
	left: 0;
	right: 0;
	opacity: 0;
	cursor: inherit !important;
}

.file-upload-control:has(.disabled) {
	background-color: #979797;
	cursor: not-allowed;
}
.file-upload-input.disabled {
	z-index: -1;
}
.copyIcon-configRow {
	color: #03a225;
}
.visa-save-changes:disabled {
	cursor: no-drop !important;
}
.visa-include-data {
	background: #f5f5f5;
	border: 3px solid #eee;
	padding: 11px 8px;
	border-radius: 10px;
	min-height: 40px;
	span {
		background: hsl(0, 0%, 90%);
		padding: 8px;
		margin: 0 5px !important;
		border-radius: 5px;
	}
}
.btn-visa-accroding {
	button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 600;
		border: 1px solid #000 !important;
		border-radius: 3px;
		background-color: #eee;
		i {
			font-weight: 900;
		}
	}
}
.config-row-new {
	border: 1px solid rgb(0, 0, 0);
	border-radius: 0px 0px 3px 3px;
	margin: -2px 5px;
	border-top: none;
}
.Package_Plan {
	display: flex;
	justify-content: center;
	h4 {
		border-bottom: 2px solid #000;
		width: 100%;
		text-align: center;
		padding-bottom: 1rem;
	}
}
.package_plan_container .control-field__body {
	padding-left: 0;
	padding-right: 0;
}
