.payment-admin {
	background-color: #f6f7fb;
	.save-buttons {
		place-content: flex-end;
		width: 100%;
	}
}

.payment-upload {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	> p {
		font-size: $text-caption;
	}
	label {
		cursor: pointer;
		margin-bottom: 0;
		.shapes {
			// margin-#{var(--left)}: 1rem;
			margin-inline-end: 1rem;
			border: 1px dashed #c6cbd0;
			border-radius: 5px;
			width: 100px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			i {
				color: #c6cbd0;
			}

			.image-uploader__img {
				display: block;
				margin: 0 auto;
				width: 100%;
				padding: 0.2rem;
				height: 100%;
				border-radius: 0;
			}
		} //shape
	}
	input {
		display: none;
	}
} //input-upload
