.subscription-plan {
	background-color: rgba(255, 255, 255, 1);
	padding: 20px;
	border-radius: 8px;
	border: 1px solid rgba(247, 246, 246, 1);
	min-height: 20%;
	.all-tabs {
		margin-bottom: 25px;
		border-bottom: 1px solid #e2e3ea !important;
		.our-taps {
			border-radius: 4px !important;
			color: #707170 !important;
			font-size: 16px !important;
			font-size: 400 !important;
			margin-inline: 0 !important;
		}
		.our-taps.is-active {
			background-color: #1e85ff !important;
			border-radius: 4px 4px 0px 0px !important;
			border: 1px solid #e2e3ea !important;
			font-size: 16px !important;
			font-weight: 400 !important;
			color: #fff !important;
		}
	}
	.subscription-header {
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		h4 {
			color: rgba(0, 0, 0, 1);
			font-size: 20px;
			font-weight: 700;
		}
		.currency {
			display: flex;
			width: 20%;
			align-items: center;
			p {
				color: #707170;
				font-size: 14px;
				width: 100%;
			}
			.control-field {
				margin: 0 !important;
				border: 1px solid #dddcdc;
				border-radius: 6px !important;
			}
			.control-field__body {
				padding: 0 !important;
			}
			.control-field__select {
				padding: 9px;
			}
		}
	}
	.module-list {
		// margin-top: 8px;
		.module-list-header {
			border-top: 1px solid rgba(231, 231, 231, 1);
			border-bottom: 1px solid rgba(231, 231, 231, 1);
			background-color: rgba(248, 249, 250, 1);
			padding-block: 8px;
			.module-list {
				p {
					color: rgba(5, 0, 1, 1);
					font-size: 20px;
					font-weight: 700;
				}
			}
			.modul-column {
				// margin-right: 10px;
				p {
					background-color: rgba(237, 239, 241, 1);
					border-radius: 4px;
					padding: 8px 20px 8px 20px;
					color: rgba(5, 0, 1, 1);
					font-size: 16px;
					// font-weight: 600;
					text-align: center;
				}
			}
		}
		.total-price {
			.total-price-item {
				p {
					color: rgba(5, 0, 1, 1);
					font-size: 20px;
					font-weight: 700;
				}
			}
		}
		.module-list-body {
			.module-list-item {
				border-top: 1px solid rgba(233, 233, 239, 1);
				border-bottom: 1px solid rgba(233, 233, 239, 1);
				background-color: #fff;
				padding-block: 8px;
				.module-name {
					p {
						color: rgba(112, 113, 112, 1);
						font-size: 16px;
						font-weight: 600;
					}
				}
				.module-price {
					input {
						border: 1px solid rgba(193, 193, 193, 1);
						border-radius: 4px;
						padding: 6px 20px 6px 20px;
						text-align: center;
					}
					input:disabled {
						border: 1px solid rgba(233, 233, 239, 1);
						background-color: rgba(194, 200, 208, 0.3);
						color: transparent;
					}

					.isParentModule:disabled,
					.active-plan-disabled:disabled {
						border: 1px solid rgba(193, 193, 193, 1);
						background-color: #fff;
						color: #222;
					}
					span {
						color: rgba(44, 48, 46, 1);
						font-size: 14px;
						font-weight: 400;
						margin-left: 5px;
					}
				}
				.sub-module-name {
					p {
						color: rgb(112, 113, 112);
						font-size: 16px;
						font-weight: 400;
					}
				}
			}
		}
	}
	.action {
		display: flex;
		flex-direction: row-reverse;
		margin-top: 25px;
		.no-shadow {
			box-shadow: none !important;
		}
	}
	input[type="checkbox"] {
		accent-color: rgba(0, 123, 255, 1);
		width: 18px;
		border-radius: 4px;
		border: 1px solid rgba(194, 200, 208, 1);
	}
}
input[type="radio"] {
	width: 17px !important;
	height: 17px !important;
}

.subscription-package-list {
	.table thead {
		height: 45px !important;
		background: #dbdade;
		padding-block: 19px !important;
		vertical-align: middle;
	}
	tr {
		height: 60px;
		vertical-align: middle;
	}
	.active-status {
		color: #019d4c;
		font-size: 16px;
	}
	.inactive-status {
		color: #f40f0f;
		font-size: 16px;
	}
	.countries {
		p {
			line-height: 1;
		}
		span {
			color: #1e85ff;
			display: flex;
			gap: 4px;
			font-size: 14px;
			cursor: pointer;
			margin-top: 4px;
			svg {
				width: 18px;
				path {
					stroke: #1e85ff;
				}
			}
		}
	}
}
.package-plan-header {
	background-color: #fff;
	border: 1px solid #f7f6f6;
	padding: 12px 0px;
	border-radius: 8px;
	margin-bottom: 24px;
	.control-field__body .control-field__label {
		color: #707170 !important;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 6px;
	}
	.date-input label {
		color: #707170 !important;
		font-size: 13px;
		font-weight: 400;
		margin-bottom: 0;
		margin-top: 12px;
	}
	.date-input {
		margin-top: 8px;
		.control-field__body {
			padding: 0.625rem !important;
			border: 1px solid #d1d6dd;
		}
	}
	.control-field__input,
	.control-field__select {
		border-radius: 5px !important;
	}
	.countries-input {
		.control-field__select {
			height: auto !important;
			div[class$="-control"] {
				height: auto !important;
				max-height: unset !important;
				div[class$="-ValueContainer"] {
					height: auto !important;
					max-height: unset !important;
				}
			}
		}
	}
}
.discount-plan {
	background-color: #fff;
	border: 1px solid #f7f6f6;
	padding: 18px;
	border-radius: 8px;
	margin-bottom: 24px;
	.check-box {
		margin-bottom: 8px;
		input[type="checkbox"] {
			width: 20px;
			height: 18px;
		}
		label {
			color: #707170;
			font-size: 18px;
			font-weight: 700;
			margin-inline: 5px;
		}
	}
	.radios {
		display: flex;
		justify-content: space-between;
		.radio {
			label {
				color: #707170;
				font-size: 18px;
				font-weight: 700;
				margin-inline: 15px;
			}
		}

		.input {
			border: 1px solid #c1c1c1;
			background-color: #fff;
			width: 150px;
			text-align: center;
			border-radius: 5px;
			margin-bottom: 8px;
			padding: 4px;
			display: flex;
			align-items: center;
			input {
				text-align: center;
				padding: 0 !important;
			}
			p {
				color: #2c302e;
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
}

.description-plan {
	background-color: #fff;
	border: 1px solid #f7f6f6;
	padding: 18px;
	border-radius: 8px;
	margin-bottom: 24px;
}
.border-gray-1 {
	border: 1px solid #c1c1c1 !important;
}
