.package {
	.package__title {
		color: #4b465c;
	}

	.package__filter {
		display: flex;

		.package__filterInputs {
			display: flex;
			gap: 12px;
			align-items: center;
			flex-wrap: wrap;
			width: 100%;

			.input-group,
			button {
				max-width: 200px;
			}
		}

		.package__filter__buttons {
			display: flex;
			gap: 12px;
			align-items: center;
		}
	}

	.package__separator {
		margin: 20px 0;
		border-bottom: 1px solid #4b465c;
	}

	.package__tableWrapper {
		overflow-x: auto;

		.package__table {
			width: 100%;
			min-width: 1200px;

			thead {
				th {
					border-top: 1px solid #4b465c;
					border-bottom: 1px solid #4b465c;
					padding: 20px 0;
				}
			}

			tbody {
				tr {
					td {
						padding: 20px 0;
						border-bottom: 1px solid #4b465c;
						border-top: 1px solid #4b465c;
					}
				}
			}
		}
	}
}
