#reserv-details {
	.reserv_details_info {
		&_key {
			background-color: #2c302e;
			color: #fff;
			border-bottom: 0.5px solid #e9e9f0;
			padding: 8px;
			font-weight: bold;
		}

		&_value {
			background-color: #f8f8f8;
			color: #2d2d2d;
			border-bottom: 0.5px solid #e9e9f0;
			padding: 8px;
			font-size: 13px;
		}

		&_value input,
		&_value .DateRangePickerInput,
		&_value .SingleDatePickerInput {
			height: 30px;
		}

		&_value .DateInput input {
			padding-inline-start: 8px !important;
		}

		&_value .control-field__select {
			height: 30px;
			padding-top: 5px;
		}

		&_value.update_Field .my-2,
		&_value.update_Field .control-field__body {
			margin: 0 !important;
			padding: 0 !important;
		}

		&_value.update_Field {
			padding: 3px 8px;
		}

		&_middle {
			.reserv_details_info_key {
				background-color: #5a5b5e;
			}
		}
		&_bottom {
			.reserv_details_info_key {
				background-color: #d1d6dd;
				color: #5a5b5e;
			}
		}
	}

	/////////// Rooms-Section ///////////
	.reserv_details_rooms {
		border: 1px solid #d1d6dd;
		width: 100%;

		&_header {
			border-bottom: 1px solid #d1d6dd;
			padding: 8px 16px;
			display: flex;
			align-items: center;

			p {
				flex-grow: 1;
				font-size: 16px;
				margin: 0;
				font-weight: bold;
				color: #313533;
			}

			label {
				border: 1px solid $green;
				color: $green;
				border-radius: 3px;
				padding: 4px 16px;
				margin: 0;
				font-weight: bold;
			}
			& label:first-of-type {
				margin-inline-end: 16px;
			}
		}

		&_form {
			text-align: end;

			.modal-body {
				padding-top: 0;
				padding-bottom: 0;
			}

			.modal-body > div {
				padding: 0;
			}

			& .control-field__body {
				padding: 8px 8px 5px;
			}

			.row {
				.control-field__append {
					inset-inline-end: 4px;
				}

				.isDisabled {
					background-color: transparent;
					input {
						background-color: #d1d6dd;
					}
				}
				.read-only {
					div[class$="-singleValue"] {
						color: #fff;
					}
					.control-field__select,
					input {
						background-color: #2c302e;
						color: white;
					}
					div[class$="-control"] {
						background-color: #2c302e !important;
					}

					div[class$="-IndicatorsContainer"] {
						display: none !important;
					}
				}

				.remove_room_icon {
					position: absolute;
					right: -17px;
					top: 50%;
					background: none;
				}
			}

			.add_new_room {
				color: $green;
				font-weight: bold;
			}
		}
	}
}
.send_tentative_modal {
	.modal-content {
		min-height: 420px !important;
		max-height: 550px !important;
		overflow-y: auto !important;
	}
}
