
.nav-tab-container {
  display: flex;
  align-items: center;
  background-color: #E8EAEC;
}
.nav-tab-item {
  padding: 0.8rem 1.6rem;
  background-color: #D2D2D2;
}
.nav-tab-item.active {
  background-color: #D7A04B;
  color: #fff !important;
}
.nav-tab-item.active * {
  color: #fff !important;
}
.nav-tab-item * {
  font-size: 1rem;
}

.promo-city-container {
  margin: 1rem 0;
  border: 1px solid #D1D6DD;
}
.promo-container .text-danger {
  color: #ff5f59 !important;
}

.promo-city-header {
  padding: .8rem;
  font-size: 1rem;
  border-bottom: 1px solid #D1D6DD;
}
.promo-city-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 60%;
}
.no-padding .control-field__body {
  padding: 0;
}

.promo-container .control-field--danger {
  border: 0 !important;
}

.upload-image-container {
  position: relative;
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
}
input[type=file], 
input[type=file]::-webkit-file-upload-button {
  cursor: pointer !important;
}
.upload-image-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
.upload-image-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
  background-color: transparent;
  color: #00AAEB;
  cursor: pointer;
}

.promo-image-preview {
  position: relative;
  height: 200px;
  border: 1px solid #D1D6DD;
  border-radius: 3px;
  background-color: #EBEBEB;
  overflow: hidden;
}
.promo-image-preview img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.promo-city-discount {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -12px 0 0 -12px;
  width: 100%;
}
.promo-city-discount-item {
  width: calc(50% - 30px);
  margin: 12px 0 0 12px;
}
.remove-discount {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-30%);
}

.promo-container .date-range-input .DateRangePicker_picker {
  transform: translateX(-50%);
  left: 30% !important;
}

@media screen and (max-width: 576px) {
  .promo-city-content {
    max-width: 100%;
  }
}