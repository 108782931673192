.notification-wrapper {
  width: 40px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 30%;
  background-color: rgba(228, 230, 235, 0.5) !important;
  border: 1px solid rgb(228, 230, 235);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .notification-number {
    position: absolute;
    background-color: rgba(255, 0, 0, 0.6) !important;

    box-shadow: rgba(255, 0, 0, 0.2) 0px 4px 12px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    border-radius: 30%;
    color: rgb(255, 255, 255);
    font-weight: 600;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.notification-bell {
  font-size: 23px;
  color: #354f5c;
}
html[dir="ltr"] .notification-wrapper .notification-number {
  right: 73%;
  bottom: 67%;
}

html[dir="rtl"] .notification-wrapper .notification-number {
  left: 73%;
  bottom: 67%;
}
.dropdown-toggle::after {
  display: none;
}

html[dir="ltr"] .notification-menu {
  top: 8% !important;
  left: 75% !important;
}
html[dir="rtl"] .notification-menu {
  top: 8% !important;
  right: 75% !important;
}

.notification-menu {
  width: 23%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  border: 1px solid rgb(228, 230, 235);
  transform: none !important;
}
.notification-content {
  text-align: start;
  margin: 0px;
}
.show-more {
  color: #004191c9;
  font-size: 0.875rem;
}
.notification-items {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  width: 100%;
  padding: 0.8rem;
  white-space: normal;
  word-spacing: normal;
  font-size: 14px;
  border-radius: 10px;
  text-align: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification-readed {
  background-color: #d9e8fac9;
font-weight: 600;}
.dot {
  color: #004191c9;
  font-size: 10px;
}
.all-notifications {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  border: 1px solid rgb(228, 230, 235);
}
