.passenger-profile{
background-color: white;
 .control-field__input{
  background-color: #EBEBEB;
}
.isDisabled input[type=text]:disabled{
  background-color: #EBEBEB;
}
.all-date-picker{
  background-color: #EBEBEB;
}
.SingleDatePicker {

  .SingleDatePickerInput__disabled{
  background-color: #EBEBEB;
  .DateInput{
    background-color: #EBEBEB !important;
  }
  }
}
.btn-back{
  background-color: #979797;
  color: white;
}
.btn-back:hover{
  background-color: #9797977f;
  color: rgb(238, 236, 236);
}
}