.static-requests {
	background-color: white;
	padding: 1rem;
	border-radius: 10px;

	.table-wrapper {
		overflow: auto;
	}

	.request-table {
		width: 100%;
		min-width: max-content;
		border-collapse: separate;

		thead {
			background-color: rgba(247, 246, 246, 1);
			color: #6c757d;
			font-weight: 600;
		}

		th,
		td {
			padding: 1rem;
			text-align: left;
		}

		th {
			border-top: 1px solid rgba(219, 218, 222, 1);
			font-weight: bold;
			text-transform: uppercase;
		}

		th:first-child {
			border-left: 1px solid rgba(219, 218, 222, 1);
			border-top-left-radius: 10px;
		}

		th:last-child {
			border-right: 1px solid rgba(219, 218, 222, 1);
			border-top-right-radius: 10px;
		}

		tr td:first-child {
			border-left: 1px solid rgba(219, 218, 222, 1);
		}

		tr td:last-child {
			border-right: 1px solid rgba(219, 218, 222, 1);
		}

		tr:last-child td {
			border-bottom: 1px solid rgba(219, 218, 222, 1);
		}

		tr:last-child td:first-child {
			border-bottom-left-radius: 10px;
		}

		tr:last-child td:last-child {
			border-bottom-right-radius: 10px;
		}

		tr:not(:last-child) td {
			border-bottom: 1px solid rgba(219, 218, 222, 1);
		}
	}

	.request-id {
		color: rgba(30, 133, 255, 1);
	}

	.request-city {
		font-size: 12px;
		color: #707170;
	}

	.view-details {
		color: rgba(30, 133, 255, 1);
		cursor: pointer;
		display: flex;
		gap: 0.2rem;
		align-items: center;
		width: fit-content;

		svg {
			width: 18px;
			height: 18px;

			path {
				stroke: rgba(30, 133, 255, 1);
			}
		}
	}

	.request-status {
		padding: 5px 10px;
		border-radius: 4px;
		text-transform: capitalize;
	}

	.pending-request {
		background-color: rgba(255, 239, 221, 1);
		color: rgba(252, 139, 9, 1);
	}

	.rejected-request {
		background-color: rgba(254, 231, 233, 1);
		color: rgba(231, 22, 22, 1);
	}

	.approved-request {
		background-color: rgba(216, 234, 222, 1);
		color: rgba(18, 142, 41, 1);
	}

	.static-requests-hotels {
		.filter-tabs {
			display: flex;
			gap: 0.5rem;
			flex-wrap: wrap;
			padding: 0;
			margin: 0;

			.rounded-filter-tab {
				background-color: transparent;
				border: 1px solid #dddcdc !important;
				border-radius: 40px;
				font-size: 16px;
				color: #707170;
				font-weight: 600;
				padding: 0.5rem 0.75rem;
				text-transform: capitalize;

				&.active {
					background-color: rgba(234, 243, 255, 1);
					color: rgba(30, 133, 255, 1);
					border-color: rgba(181, 215, 255, 1);
				}
			}
		}
	}

	.hotel-edit-form {
		textarea {
			min-height: 38px;
			height: auto;
		}

		.slelected-amenities-list {
			margin: 0;
			padding: 0;
			margin-top: 0.5rem;
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;

			.slelected-amenities-item {
				button {
					background-color: rgba(243, 243, 243, 1);
					color: black;
					border: none;
					outline: none;
					border-radius: 5px;
					padding: 0.5rem 1rem;
					cursor: pointer;
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}
			}
		}

		.phone-field-wrapper {
			.optional-label {
				color: #6b7280;
				font-size: 0.875rem;
			}

			.phone-field-label {
				font-size: 16px;
				font-family: "Cairo";
				color: #000;
				margin-bottom: 0;
				width: 100%;
				text-align: var(--left);
				text-transform: capitalize;
			}

			.phone-field {
				display: flex;
				gap: 0.5rem;
				width: 100%;
				max-width: 28rem;

				.country-option,
				.select-value {
					display: flex;
					align-items: center;
					gap: 0.5rem;

					img {
						object-fit: cover;
						width: 2rem;
						height: 1.5rem;
					}

					.country-flag-fallback {
						width: 2rem;
						height: 1.5rem;
						background-color: #f3f4f6;
						border-radius: 0.375rem;
					}

					.country-code {
						color: #6b7280;
					}
				}

				.select-value-container {
					height: 100% !important;
				}

				.select-control {
					border-color: #e5e7eb;
					height: 100% !important;

					&:hover {
						border-color: #d1d5db;
					}
				}

				.select-option {
					cursor: pointer;
					background-color: white;

					&.focused {
						background-color: #f3f4f6;
					}
				}

				.select-menu {
					border-width: 1px;
					border-color: #e5e7eb;
				}

				.phone-input-wrapper {
					position: relative;
					flex-grow: 1;
					display: flex;
					gap: 0.5rem;
					border: 1px solid #e5e7eb;
					border-radius: 4px;
					padding-inline: 0.5rem;

					div {
						color: #6b7280;
						-webkit-user-select: none;
						user-select: none;
						align-items: center;
						display: flex;
					}

					input {
						padding-right: 0.5rem;
						border-width: 1px;
						border: none;
						outline: none;
						width: 100%;
						height: 3.5rem;
						font-size: 1.125rem;
						line-height: 1.75rem;
						color: #374151;
						background-color: #ffffff;
						transition-property: color, background-color, border-color,
							text-decoration-color, fill, stroke;
						transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
						transition-duration: 300ms;
						border-radius: 4px;
						height: 38px;
					}
				}
			}
		}
	}

	.image-upload {
		width: 100%;
		padding-inline: 10px;
		padding-bottom: 0.3125rem;
		padding-top: 1.25rem;

		input {
			display: none;
		}

		label {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
			padding: 0.5rem;
			border: 1px solid #dddcdc;
			border-style: dashed;
			border-radius: 5px;
			cursor: pointer;
			font-size: 16px;
			color: #707170;

			.icon-wrapper {
				padding: 2rem;
				margin-bottom: 1rem;
				border-radius: 8px;
				background-color: #f7f6f6;
				position: relative;

				.arrow-up-icon {
					position: absolute;
					right: 0;
					bottom: 0;
					transform: translate(35%, 35%);
					background-color: white;
					border-radius: 12px;
					padding: 0.5rem;
				}
			}
		}

		.image-card-list {
			display: flex;
			gap: 0.5rem;
			flex-wrap: wrap;
		}

		.image-card {
			position: relative;
			display: flex;
			gap: 1rem;
			align-items: center;
			padding: 0.5rem;
			border: 1px solid #dddcdc;
			border-radius: 5px;
			width: fit-content;
			cursor: default;

			.image-card-overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
				border-radius: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;
				transition: opacity 0.3s;
			}

			&:hover .image-card-overlay {
				opacity: 1;
			}

			img {
				width: 100px;
				height: 100px;
				object-fit: cover;
				border-radius: 5px;
			}

			div:has(.delete-image) {
				margin-inline-start: auto;
			}

			.image-details {
				display: flex;
				flex-direction: column;
				overflow: hidden;
				flex: 1;

				.image-name {
					line-height: 1 !important;
					margin-bottom: 0.3rem !important;
					color: black;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			.delete-image {
				cursor: pointer;
				color: #e43c4c;
				background: #f5d4d4;
				padding: 0.5rem;
				font-size: 16px;
				border-radius: 4px;
				line-height: 1;
				cursor: pointer;
			}
		}
	}
}

#request-details-tooltip {
	.tooltip-inner {
		padding: 0;
		padding-block: 0.5rem;
		text-align: left;
		background-color: rgba(49, 52, 54, 0.88);
		border-radius: 12px;
		font-size: 1rem;
		max-width: fit-content;

		p {
			padding: 0.5rem 1rem;
		}

		.tooltip-title {
			font-size: 18px;
			font-weight: 700;
		}

		p:not(:is(.tooltip-title)) {
			font-size: 16px;
			font-weight: 600;
		}

		.tooltip-content {
			display: flex;
			gap: 0.5rem;
			align-items: center;
			justify-content: space-between;
		}

		hr {
			margin-block: 0.2rem;
		}
	}
}

#reject-modal {
	textarea {
		min-height: 38px;
		height: auto;
	}
}
